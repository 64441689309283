import { ReactElement } from 'react'
import { Fragment } from 'react/jsx-runtime'

const toJsx = (text?: string | number | null): ReactElement[] => {
  text = `${text ?? ''}`.trim()

  if (!text) return []

  return text.split(/\s*\n+/g).map((d, i) => (
    <Fragment key={`${i}@$@${d}`}>
      {i !== 0 && <br />}
      {d}
    </Fragment>
  ))
}

/** 多行文本显示（组件方式） */
export const MultilineTextShow = Object.assign(
  ({ text }: { text?: string | number | null }) => {
    return toJsx(text)
  },
  {
    /** 多行文本显示（生成 jsx，由数组长度可知行数，推荐） */
    toJsx,
  },
)
