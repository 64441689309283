import { memo, useState } from 'react'

import { PageEmpty } from '@/components/PageEmpty'
import { OrderPageLayout } from '../../components/OrderPageLayout'
import { CtxProvider, Filter } from '../../Ctx'
import { Action } from './Action'
import { OrderTable } from './OrderTable'
import { OrderTabs } from './OrderTabs'
import { DEFAULT_TAB, TABS } from './utils'

export const OrderAudit = memo(function OrderAudit() {
  const [activeTab, setActiveTab] = useState<string>(DEFAULT_TAB)
  if (TABS.includes(activeTab)) {
    return (
      <CtxProvider key={activeTab} tabType={+activeTab} activeTab={activeTab} setActiveTab={setActiveTab}>
        <OrderPageLayout
          headerFilter={<Filter />}
          headerAction={<Action />}
          tabs={<OrderTabs />}
          table={<OrderTable />}
        />
      </CtxProvider>
    )
  }
  return <PageEmpty type="notFound" />
})
