import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TAB_TYPE } from '../consts'

/** 默认页签 */
export const DEFAULT_TAB: string = TAB_TYPE.待审核 + ''

/** 所有页签值 */
export const TABS: string[] = [
  TAB_TYPE.待匹配,
  TAB_TYPE.待付款,
  TAB_TYPE.待审核,
  TAB_TYPE.待处理,
  TAB_TYPE.已搁置,
  TAB_TYPE.待发货,
  TAB_TYPE.已发货或订单追踪,
  TAB_TYPE.已取消,
  TAB_TYPE.非ShipO,
].map(v => v + '')

/** 页签名 map */
export const useTAB_NAME_MAP = (): Record<string, string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      [TAB_TYPE.待匹配]: t('Order.dai-pi-pei'),
      [TAB_TYPE.待付款]: t('Order.dai-fu-kuan'),
      [TAB_TYPE.待审核]: t('Order.dai-shen-he'),
      [TAB_TYPE.待处理]: t('Order.dai-chu-li'),
      [TAB_TYPE.已搁置]: t('Order.yi-ge-zhi'),
      [TAB_TYPE.待发货]: t('Order.dai-fa-huo'),
      [TAB_TYPE.已发货或订单追踪]: t('Order.yi-fa-huo'),
      [TAB_TYPE.已取消]: t('Order.yi-qu-xiao'),
      [TAB_TYPE.非ShipO]: t('Order.fei-shipo'),
    }),
    [t],
  )
}
