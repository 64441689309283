import { useContextSelector } from 'use-context-selector'
import classNames from 'classnames'
import { TableProps } from 'antd'

import { ICusTableProps } from '@/components/CusTable'
import { ExpandIcon } from '@/components/ExpandIcon'
import { SPIN_DELAY } from '@/components/Loading'
import { getDefaultPaginationProps } from '@/utils/pagination'
import { TAB_TYPE, TAB_TYPE2 } from '../consts'
import type { IOrder } from '../interface'
import { Ctx } from './Ctx'
import { ICtxVal } from './interface'
import styles from './styles.module.less'

type TRowClassNameFn = Exclude<TableProps<IOrder>['rowClassName'], string | undefined>

/** 统一 TableProps */
export const useTableProps = ({ rowClassName }: { rowClassName?: TRowClassNameFn } = {}): ICusTableProps & {
  ref: ICtxVal['tableRef']
} => {
  const tabType = useContextSelector(Ctx, v => v.tabType)
  const isEmployee = useContextSelector(Ctx, v => v.isEmployee)
  const selectedRowKeys = useContextSelector(Ctx, v => v.selectedRowKeys)
  const expandedRowKeys = useContextSelector(Ctx, v => v.expandedRowKeys)
  const loading = useContextSelector(Ctx, v => v.loading)
  const data = useContextSelector(Ctx, v => v.data)
  const page = useContextSelector(Ctx, v => v.page)
  const size = useContextSelector(Ctx, v => v.size)
  const getList = useContextSelector(Ctx, v => v.getList)
  const tableRef = useContextSelector(Ctx, v => v.tableRef)
  const setState = useContextSelector(Ctx, v => v.setState)

  let rowSelection: TableProps<IOrder>['rowSelection']
  const showCheckbox = [
    ...(!isEmployee
      ? [TAB_TYPE.待审核, TAB_TYPE.待处理]
      : [TAB_TYPE2.待审核, TAB_TYPE2.待预报, TAB_TYPE2.待打印, TAB_TYPE2.待推送]),
  ].includes(tabType)
  if (showCheckbox || data?.records.some(d => d.subOrders?.length)) {
    rowSelection = {
      fixed: 'left',
      selectedRowKeys,
      onChange: keys => setState({ selectedRowKeys: keys as string[] }),
      getCheckboxProps: ({ subOrders }) => ({
        disabled: showCheckbox ? !!subOrders?.length : true,
      }),
      hideSelectAll: !showCheckbox,
      renderCell: (_v, record, _index, originNode) => {
        const { sysOrderId, subOrders } = record
        if (subOrders?.length) {
          const collapsed = !expandedRowKeys.includes(record.sysOrderId)
          return (
            <ExpandIcon
              collapsed={collapsed}
              onClick={() => {
                setState({
                  expandedRowKeys: collapsed
                    ? expandedRowKeys.concat(sysOrderId)
                    : expandedRowKeys.filter(d => d !== sysOrderId),
                })
              }}
            />
          )
        }
        if (showCheckbox) return originNode
      },
    }
  }

  let expandable: TableProps<IOrder>['expandable']
  if (rowSelection) {
    expandable = {
      childrenColumnName: 'subOrders',
      expandedRowKeys,
      expandIcon: () => null,
      indentSize: 0,
    }
  }

  const mergedRowClassName: TRowClassNameFn = (record, index, indent) => {
    let result = ''
    const { subOrders, $parentKey } = record
    if (subOrders?.length) {
      result = classNames(result, styles.parentRow)
    } else if ($parentKey) {
      result = classNames(result, styles.subRow)
    }
    if (rowClassName) {
      result = classNames(result, rowClassName(record, index, indent))
    }
    return result
  }

  return {
    size: 'middle',
    rowKey: 'sysOrderId',
    loading: { spinning: loading, delay: SPIN_DELAY },
    dataSource: data?.records,
    rowSelection,
    expandable,
    rowClassName: mergedRowClassName,
    ref: tableRef,
    verticalFlexLayout: true,
    pagination: {
      ...getDefaultPaginationProps(data?.total || 0),
      current: page,
      pageSize: size,
      onChange: (page: number, size: number) => getList({ page, size }),
    },
  } as const
}
