import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { message, Modal } from 'antd'

import { apiReviveProcess } from '../apis'

/** 移入待处理操作 */
export const ReviveProcess = (props: {
  sysShopId: string
  sysOrderId: string
  /** 操作成功回调 */
  onSuccess?: () => void
  /** 按钮节点 */
  children: (handle: {
    /** 触发操作 */
    handleClick: () => void
  }) => ReactNode
}) => {
  const { t } = useTranslation()
  const { sysShopId, sysOrderId, onSuccess, children } = props

  const handleClick = () => {
    Modal.confirm({
      title: t('Order.que-ren-yi-ru-dai-chu-li-ma'),
      onOk: async () => {
        await apiReviveProcess({ sysShopId, sysOrderId })
        message.success(t('2-common.cao-zuo-cheng-gong'))
        onSuccess?.()
      },
    })
  }

  return <>{children({ handleClick })}</>
}
