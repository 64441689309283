import { memo } from 'react'
import type { ColumnsType } from 'antd/es/table'

import { CusTable } from '@/components/CusTable'
import { Cells, useTableProps } from '../Ctx'
import { useColumnInfo } from '../Ctx/useColumnInfo'
import { IOrder } from '../interface'

export const OrderTable = memo(function OrderTable() {
  const columnInfo = useColumnInfo()
  const tableProps = useTableProps()

  const columns: ColumnsType<IOrder> = [
    {
      ...columnInfo.orderNumber,
      render: (_v, record) => <Cells.OrderNumberCell record={record} />,
    },
    {
      ...columnInfo.productInfo,
      render: (_v, record) => <Cells.ProductInfoCell record={record} />,
    },
    {
      ...columnInfo.channel,
      render: (_v, record) => <Cells.ChannelCell record={record} />,
    },
    {
      ...columnInfo.currentTotalPrice,
      render: (_v, record) => <Cells.CurrentTotalPriceCell record={record} />,
    },
    {
      ...columnInfo.orderAddress,
      render: (_v, record) => <Cells.OrderAddressCell record={record} />,
    },
    {
      ...columnInfo.orderTime,
      render: (_v, record) => <Cells.OrderTimeCell record={record} />,
    },
    {
      ...columnInfo.fulfillment,
      render: (_v, record) => <Cells.FulfillmentCell record={record} />,
    },
    {
      ...columnInfo.orderStatus,
      render: (_v, record) => <Cells.OrderStatusCell record={record} />,
    },
    {
      ...columnInfo.action,
      render: (_v, record) => <Cells.ActionCell record={record} />,
    },
  ]

  return <CusTable {...tableProps} columns={columns} />
})
