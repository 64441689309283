import { useTranslation } from 'react-i18next'
import { Popover, theme } from 'antd'
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons'

export const CheckStatusShow = ({
  isErr,
  errTip,
}: {
  isErr?: boolean
  /** @default'校验不通过，请检查' */
  errTip?: string
}) => {
  const { t } = useTranslation()
  const { token } = theme.useToken()
  if (isErr) {
    return (
      <Popover mouseEnterDelay={0} content={errTip || t('Order.xiao-yan-bu-tong-guo-qing-jian-cha')}>
        <ExclamationCircleFilled style={{ marginLeft: 4, color: token.colorError }} />
      </Popover>
    )
  }
  return <CheckCircleFilled style={{ marginLeft: 4, color: token.colorSuccess }} />
}
