import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TAB_TYPE2 } from '../../consts'

/** 默认页签 */
export const DEFAULT_TAB: string = TAB_TYPE2.待审核 + ''

/** 所有页签值 */
export const TABS: string[] = [
  TAB_TYPE2.待审核,
  TAB_TYPE2.待预报,
  TAB_TYPE2.待打印,
  TAB_TYPE2.待推送,
  TAB_TYPE2.待打包,
  TAB_TYPE2.已发货或运单追踪,
  TAB_TYPE2.已取消,
].map(v => v + '')

/** 页签名 map */
export const useTAB_NAME_MAP = (): Record<string, string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      [TAB_TYPE2.待审核]: t('Order.dai-shen-he'),
      [TAB_TYPE2.待预报]: t('Order.dai-yu-bao'),
      [TAB_TYPE2.待打印]: t('Order.dai-da-yin'),
      [TAB_TYPE2.待推送]: t('Order.dai-tui-song'),
      [TAB_TYPE2.待打包]: t('Order.dai-da-bao'),
      [TAB_TYPE2.已发货或运单追踪]: t('Order.yi-fa-huo'),
      [TAB_TYPE2.已取消]: t('Order.yi-qu-xiao'),
    }),
    [t],
  )
}
