import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TAB_TYPE } from '../consts'

/** 默认页签 */
export const DEFAULT_TAB: string = TAB_TYPE.运单_无货 + ''

/** 所有页签值 */
export const TABS: string[] = [TAB_TYPE.运单_无货, TAB_TYPE.运单_有货].map(v => v + '')

/** 页签名 map */
export const useTAB_NAME_MAP = (): Record<string, string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      [TAB_TYPE.运单_无货]: t('Order.wu-huo'),
      [TAB_TYPE.运单_有货]: t('Order.you-huo'),
    }),
    [t],
  )
}
