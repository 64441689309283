import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { message, Modal } from 'antd'

import { apiApprovals } from '../../apis'
import { RejectModal } from './RejectModal'

/** 员工侧审核操作 */
export const EmployeeApprovals = (props: {
  /** 传入数组则视为批量，提示语有些区别 */
  sysOrderIds: string | string[]
  /** 审核类型：0-审核不通过; 1-审核通过; */
  auditType: 0 | 1
  /** 操作成功回调 */
  onSuccess?: () => void
  /** 按钮节点 */
  children: (handle: {
    /** 触发操作 */
    handleClick: () => void
  }) => ReactNode
}) => {
  const { t } = useTranslation()
  const { sysOrderIds, auditType, onSuccess, children } = props
  const ids = [sysOrderIds].flat(1)
  const isBatch = Array.isArray(sysOrderIds)

  const handleClick = () => {
    if (isBatch && !ids.length) {
      return void message.info(t('Order.qing-gou-xuan-xu-yao-pi-liang-cao-zuo-de-ding-dan'))
    }

    if (auditType === 1) {
      Modal.confirm({
        title: isBatch
          ? t('Order.que-ren-yao-pi-liang-shen-he-tong-guo-ma')
          : t('Order.que-ren-yao-shen-he-tong-guo-ma'),
        content: isBatch ? t('Order.gong-xuan-zhong-len-tiao-shu-ju', { len: ids.length }) : undefined,
        onOk: async () => {
          await apiApprovals({
            auditResult: 1,
            sysOrderIds: ids,
          })
          message.success(t('2-common.cao-zuo-cheng-gong'))
          onSuccess?.()
        },
      })
    }

    if (auditType === 0) {
      RejectModal.open({
        sysOrderIds,
        onSuccess,
      })
    }
  }

  return <>{children({ handleClick })}</>
}
