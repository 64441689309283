import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EventEmitter } from '@/utils//eventEmitter'
import { STATUS, STATUS2 } from './consts'
import type { IOrder } from './interface'

/** 事件中心 */
export const emitter = new EventEmitter<{
  /** 更新主列表项 */
  ['changeOrderRecord']: [data: { sysOrderId: string; changedValues: Partial<IOrder> }]
}>()

/** 订单地址字段:字段名 */
export const useORDER_ADDRESS_NAME_MAP = () => {
  const { t } = useTranslation()
  return useMemo(
    () =>
      ({
        name: t('Order.shou-jian-ren'),
        phone: t('Order.dian-hua'),
        country: t('Order.guo-jia'),
        countryCode: '', // 目前仅为了类型完整
        province: t('Order.sheng-fen'),
        city: t('Order.cheng-shi'),
        address1: t('Order.di-zhi-1'),
        address2: t('Order.di-zhi-2'),
        company: t('Order.gong-si'),
        zip: t('Order.you-bian'),
      }) as const,
    [t],
  )
}

/** 订单状态:状态名称 */
export const useORDER_STATUS_NAME_MAP = (isEmployee: boolean): Record<number | '-1', string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () =>
      !isEmployee
        ? {
            '-1': t('2-common.quan-bu'),
            [STATUS.未付款]: t('Order.wei-fu-kuan'),
            [STATUS.已付款]: t('Order.yi-fu-kuan'),
            [STATUS.部分退款]: t('Order.bu-fen-tui-kuan'),
            [STATUS.已退款]: t('Order.yi-tui-kuan'),
            [STATUS.已取消]: t('Order.yi-qu-xiao'),
            [STATUS.ShipO审核中]: t('Order.shipo-shen-he-zhong'),
            [STATUS.已驳回]: t('Order.yi-bo-hui'),
            [STATUS.待预报]: t('Order.dai-yu-bao'),
            [STATUS.预报失败]: t('Order.yu-bao-shi-bai'),
            [STATUS.有货]: t('Order.you-huo'),
            [STATUS.无货]: t('Order.wu-huo'),
            [STATUS.待打包]: t('Order.dai-da-bao'),
            [STATUS.已出库]: t('Order.yi-chu-ku'),
            [STATUS.已发货]: t('Order.yi-fa-huo'),
            [STATUS.已签收]: t('Order.yi-qian-shou'),
          }
        : {
            '-1': t('2-common.quan-bu'),
            [STATUS2.有货]: t('Order.you-dai'),
            [STATUS2.无货]: t('Order.wu-huo'),
            [STATUS2.待预报]: t('Order.dai-yu-bao'),
            [STATUS2.预报失败]: t('Order.yu-bao-shi-bai'),
            [STATUS2.待打印]: t('Order.dai-da-yin'),
            [STATUS2.打印失败]: t('Order.da-yin-shi-bai'),
            [STATUS2.待推送]: t('Order.dai-tui-song'),
            [STATUS2.推送失败]: t('Order.tui-song-shi-bai'),
            [STATUS2.待打包]: t('Order.dai-da-bao'),
            [STATUS2.已发货]: t('Order.yi-fa-huo'),
            [STATUS2.已签收]: t('Order.yi-qian-shou'),
          },
    [isEmployee, t],
  )
}

/** 获取扁平化的订单列表 */
export const getFlatOrderRecords = (orderRecords: IOrder[], pickKeys?: string[]): IOrder[] => {
  const result = orderRecords.map(d => [d, ...(d.subOrders || [])]).flat(1)
  if (Array.isArray(pickKeys)) {
    return result.filter(d => pickKeys.includes(d.sysOrderId))
  }
  return result
}
