import { createContext } from 'use-context-selector'
import _ from 'lodash'

import { createPopupSlot } from '@/services/popupSlot'
import { ICtxState, ICtxVal } from './interface'

export const popupSlot = createPopupSlot()

export const createInitCtxState = (
  mergeState?: Partial<ICtxState>,
): { [P in keyof Required<ICtxState>]: ICtxState[P] } => {
  return {
    loading: true,
    detail: null,
    ...mergeState,
  }
}

export const initCtxState = createInitCtxState()

export const initCtxVal: ICtxVal = {
  ...initCtxState,
  isEmployee: false,
  platForm: 1,
  sysShopId: '',
  shopName: '',
  sysOrderId: '',
  orderNumber: '',
  defaultActiveTab: 'address',
  editable: false,
  onOperationSuccess: _.noop,
  setState: () => undefined,
  ctxValRef: {
    get current() {
      return initCtxVal
    },
  },
}

export const CTX_STATE_KEYS = Object.keys(initCtxState)

export const Ctx = createContext<ICtxVal>(initCtxVal)
