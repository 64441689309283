import { memo } from 'react'
import _ from 'lodash'

import { OrderPageLayout } from '../../components/OrderPageLayout'
import { TAB_TYPE2 } from '../../consts'
import { CtxProvider, Filter } from '../../Ctx'
import { OrderTable } from './OrderTable'

export const OrderError = memo(function OrderError() {
  return (
    <CtxProvider tabType={TAB_TYPE2.异常履约单} activeTab="" setActiveTab={_.noop}>
      <OrderPageLayout headerFilter={<Filter />} table={<OrderTable />} />
    </CtxProvider>
  )
})
