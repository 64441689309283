import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { produce } from 'immer'
import { Button, Col, Descriptions, Form, Input, Row, Select, Space } from 'antd'

import { useCOUNTRY_OPTS } from '@/pages/Biz/consts/country'
import { formAutoTrimOnBlur, selectFilterOption } from '@/utils/utils'
import { apiUpdateOrderAddress } from '../../apis'
import type { IOrder, IOrderDetail } from '../../interface'
import { useORDER_ADDRESS_NAME_MAP } from '../../utils'
import { Ctx } from './Ctx'
import { getAddressRule } from './utils'

/** 收货地址表单值类型 */
export type TAddressFormValues = Record<keyof Omit<IOrderDetail['orderAddress'], 'sysOrderId'>, string>

/** 收货地址展示 */
export const AddressInfoShow = ({
  values,
  requiredMark,
}: {
  values: Omit<IOrder['orderAddress'], 'sysOrderId'>
  requiredMark?: boolean
}) => {
  const nameMap = useORDER_ADDRESS_NAME_MAP()
  const getDescriptionLabel = (key: keyof TAddressFormValues) => {
    const { required } = getAddressRule(key)
    if (required)
      return (
        <>
          {requiredMark && <span style={{ marginRight: 4, color: 'red' }}>*</span>}
          {nameMap[key]}
        </>
      )
    return nameMap[key]
  }
  const getDescriptionVal = (key: keyof TAddressFormValues) => values[key] || '--'
  return (
    <Descriptions
      size="small"
      bordered
      column={6}
      labelStyle={{ whiteSpace: 'nowrap' }}
      items={[
        {
          span: 3,
          label: getDescriptionLabel('name'),
          children: getDescriptionVal('name'),
        },
        {
          span: 3,
          label: getDescriptionLabel('phone'),
          children: getDescriptionVal('phone'),
        },
        {
          span: 2,
          label: getDescriptionLabel('country'),
          children: getDescriptionVal('country'),
        },
        {
          span: 2,
          label: getDescriptionLabel('province'),
          children: getDescriptionVal('province'),
        },
        {
          span: 2,
          label: getDescriptionLabel('city'),
          children: getDescriptionVal('city'),
        },
        {
          span: 6,
          label: getDescriptionLabel('address1'),
          children: getDescriptionVal('address1'),
        },
        {
          span: 6,
          label: getDescriptionLabel('address2'),
          children: getDescriptionVal('address2'),
        },
        {
          span: 3,
          label: getDescriptionLabel('company'),
          children: getDescriptionVal('company'),
        },
        {
          span: 3,
          label: getDescriptionLabel('zip'),
          children: getDescriptionVal('zip'),
        },
      ]}
    />
  )
}

/** 收货地址 */
export const CAddress = memo(function CAddress() {
  const { t } = useTranslation()
  const nameMap = useORDER_ADDRESS_NAME_MAP()
  const sysShopId = useContextSelector(Ctx, v => v.sysShopId)
  const sysOrderId = useContextSelector(Ctx, v => v.sysOrderId)
  const orderAddress = useContextSelector(Ctx, v => v.detail!.orderAddress)
  const editable = useContextSelector(Ctx, v => v.editable)
  const setState = useContextSelector(Ctx, v => v.setState)
  const countryOpts = useCOUNTRY_OPTS()

  const [editing, setEditing] = useState(false)
  const [form] = Form.useForm<TAddressFormValues>()

  const initialValues = useMemo<TAddressFormValues>(() => {
    return {
      name: orderAddress.name || '',
      phone: orderAddress.phone || '',
      country: orderAddress.country || '',
      countryCode: orderAddress.countryCode || '',
      province: orderAddress.province || '',
      city: orderAddress.city || '',
      address1: orderAddress.address1 || '',
      address2: orderAddress.address2 || '',
      company: orderAddress.company || '',
      zip: orderAddress.zip || '',
    }
  }, [orderAddress])

  return (
    <div>
      {!editing ? (
        // 查阅模式
        <AddressInfoShow values={initialValues} requiredMark={editable} />
      ) : (
        // 编辑模式
        <Form
          form={form}
          initialValues={initialValues}
          onBlur={e => {
            formAutoTrimOnBlur({
              event: e,
              form,
              includeField: Object.keys(initialValues),
            })
          }}
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label={nameMap.name} name="name" rules={[getAddressRule('name')]}>
                <Input placeholder={t('2-common.qing-shu-ru')} allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('Order.dian-hua')} name="phone" rules={[getAddressRule('phone')]}>
                <Input placeholder={t('2-common.qing-shu-ru')} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label={nameMap.country} name="countryCode" rules={[getAddressRule('countryCode')]}>
                <Select
                  options={countryOpts}
                  showSearch
                  filterOption={selectFilterOption}
                  onChange={(_v, opt: any) => form.setFieldValue('country', opt?.label || '')}
                />
              </Form.Item>
              <Form.Item hidden name="country">
                <div />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={nameMap.province} name="province" rules={[getAddressRule('province')]}>
                <Input placeholder={t('2-common.qing-shu-ru')} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={nameMap.city} name="city" rules={[getAddressRule('city')]}>
                <Input placeholder={t('2-common.qing-shu-ru')} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={24}>
              <Form.Item label={nameMap.address1} name="address1" rules={[getAddressRule('address1')]}>
                <Input placeholder={t('2-common.qing-shu-ru')} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={24}>
              <Form.Item label={nameMap.address2} name="address2" rules={[getAddressRule('address2')]}>
                <Input placeholder={t('2-common.qing-shu-ru')} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label={nameMap.company} name="company" rules={[getAddressRule('company')]}>
                <Input placeholder={t('2-common.qing-shu-ru')} allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={nameMap.zip} name="zip" rules={[getAddressRule('zip')]}>
                <Input placeholder={t('2-common.qing-shu-ru')} allowClear />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}

      {editable && (
        <div style={{ marginTop: editing ? 0 : 16, textAlign: 'right' }}>
          {!editing ? (
            <Button
              size="small"
              type="primary"
              onClick={() => {
                form.setFieldsValue(initialValues)
                setEditing(true)
              }}
            >
              {t('2-common.bian-ji')}
            </Button>
          ) : (
            <Space>
              <Button
                size="small"
                onClick={() => {
                  form.setFieldsValue(initialValues)
                  setEditing(false)
                }}
              >
                {t('2-common.qu-xiao')}
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={async () => {
                  let values = await form.validateFields()
                  values = form.getFieldsValue(true)
                  try {
                    setState({ loading: t('Order.zheng-zai-bao-cun') })
                    await apiUpdateOrderAddress({
                      ...values,
                      sysShopId,
                      sysOrderId,
                      countryChangedFlag: values.countryCode !== orderAddress.countryCode,
                    })
                    setState(prev => {
                      const nextDetail = produce(prev.detail!, draft => {
                        Object.assign(draft.orderAddress, values)
                      })
                      return { detail: nextDetail }
                    })
                    setEditing(false)
                  } finally {
                    setState({ loading: false })
                  }
                }}
              >
                {t('2-common.bao-cun')}
              </Button>
            </Space>
          )}
        </div>
      )}
    </div>
  )
})
