import { ReactNode } from 'react'

import styles from './styles.module.less'

/** 订单页面布局 */
export const OrderPageLayout = ({
  headerFilter,
  headerAction,
  tabs,
  table,
}: {
  headerFilter: ReactNode
  headerAction?: ReactNode
  tabs?: ReactNode
  table: ReactNode
}) => {
  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <div className={styles.header_filter}>{headerFilter}</div>
        {!!headerAction && <div className={styles.header_action}>{headerAction}</div>}
      </div>

      {!!tabs && <div className={styles.tabs}>{tabs}</div>}

      <div className={styles.table}>{table}</div>
    </div>
  )
}
