import { t } from 'i18next'
import { message, Modal } from 'antd'

import { apiRestoreOrder } from '../apis'

/** 还原订单 */
export const restoreOrder = ({
  sysShopId,
  sysOrderId,
  onSuccess,
}: {
  sysShopId: string
  sysOrderId: string
  /** 操作成功回调 */
  onSuccess?: () => void
}) => {
  Modal.confirm({
    title: t('Order.que-ren-yao-huan-yuan-ding-dan-ma'),
    onOk: async () => {
      await apiRestoreOrder({ sysShopId, sysOrderId })
      message.success(t('2-common.cao-zuo-cheng-gong'))
      onSuccess?.()
    },
  })
}
