import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { useRequest } from 'ahooks'
import { produce } from 'immer'
import { Button, Col, Descriptions, Form, Row, Select, Space, Spin } from 'antd'

import { selectFilterOption, toThousands } from '@/utils/utils'
import { apiAddOrEditLogistics, apiGetEstimatedFreight, apiGetOptionalLogistics } from '../../apis'
import { Ctx } from './Ctx'

type TFormValues = {
  channelCode: string | undefined
  channelEName: string | undefined
  estimatedFreight: string | undefined
  registrationFreight: string | undefined
}

/** 仓库物流 */
export const CLogistics = memo(function CLogistics() {
  const { t } = useTranslation()
  const detail = useContextSelector(Ctx, v => v.detail!)
  const editable = useContextSelector(Ctx, v => v.editable)
  const setState = useContextSelector(Ctx, v => v.setState)

  const [editing, setEditing] = useState(false)
  const [form] = Form.useForm<TFormValues>()
  const estimatedFreight = Form.useWatch(v => v.estimatedFreight, form)
  const registrationFreight = Form.useWatch(v => v.registrationFreight, form)

  const initialValues: TFormValues = {
    channelCode: detail.channelCode || undefined,
    channelEName: detail.channelEName || undefined,
    estimatedFreight: detail.estimatedFreight || undefined,
    registrationFreight: detail.registrationFreight || undefined,
  }

  const {
    loading: freightLoading,
    run: queryFreight,
    error: freightError,
  } = useRequest((channelCode: string) => apiGetEstimatedFreight(detail.sysOrderId, channelCode), {
    manual: true,
    onBefore: () => {
      form.setFieldValue('estimatedFreight', undefined)
      form.setFieldValue('registrationFreight', undefined)
    },
    onSuccess: data => {
      form.setFieldValue('estimatedFreight', data.estimatedFreight)
      form.setFieldValue('registrationFreight', data.registrationFreight)
    },
  })

  const {
    loading: channelOptsLoading,
    data: channelOpts,
    run: queryChannelOpts,
  } = useRequest(
    async () => {
      const res = await apiGetOptionalLogistics(detail.sysOrderId)
      return res.map(d => ({ value: d.channelCode, label: d.channelEName || d.channelCode }))
    },
    { manual: true },
  )
  useEffect(() => {
    if (editable && editing && !channelOpts) queryChannelOpts()
  }, [channelOpts, editable, editing, queryChannelOpts])

  const getRequiredNode = () => editable && <span style={{ marginRight: 4, color: 'red' }}>*</span>

  return (
    <div>
      {!editing ? (
        // 查阅模式
        <Descriptions
          size="small"
          bordered
          column={4}
          items={[
            {
              span: 4,
              label: (
                <>
                  {getRequiredNode()}
                  {t('Order.cang-ku')}
                </>
              ),
              children: detail.stash || '--',
            },
            {
              span: 2,
              label: (
                <>
                  {getRequiredNode()}
                  {t('Order.wu-liu-qu-dao')}
                </>
              ),
              children: detail.channelEName || '--',
            },
            {
              span: 1,
              label: t('Order.yu-gu-yun-fei'),
              children: <span style={{ whiteSpace: 'nowrap' }}>{toThousands(detail.estimatedFreight)}</span>,
            },
            {
              span: 1,
              label: t('Order.yu-gu-gua-hao-fei'),
              children: <span style={{ whiteSpace: 'nowrap' }}>{toThousands(detail.registrationFreight)}</span>,
            },
          ]}
        />
      ) : (
        // 编辑模式
        <Form form={form} initialValues={initialValues}>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label={t('Order.cang-ku')} required>
                <Select disabled value={detail.stash || t('Order.wei-cang')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label={t('Order.wu-liu-qu-dao')}
                name="channelCode"
                hasFeedback={channelOptsLoading ? true : undefined}
                validateStatus={channelOptsLoading ? 'validating' : undefined}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={t('2-common.qing-xuan-ze')}
                  options={channelOpts}
                  showSearch
                  filterOption={selectFilterOption}
                  onChange={(val, opt?: any) => {
                    queryFreight(val)
                    form.setFieldValue('channelEName', opt?.label || '')
                  }}
                />
              </Form.Item>
              <Form.Item hidden name="channelEName">
                <div />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={t('Order.yun-fei-yu-gu')}>
                {freightLoading ? <Spin /> : toThousands(estimatedFreight)}
              </Form.Item>
              <Form.Item hidden name="estimatedFreight" rules={[{ required: true }]}>
                <div />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={t('Order.yu-gu-gua-hao-fei')}>
                {freightLoading ? <Spin /> : toThousands(registrationFreight)}
              </Form.Item>
              <Form.Item hidden name="registrationFreight" rules={[{ required: true }]}>
                <div />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}

      {editable && (
        <div style={{ marginTop: editing ? 0 : 16, textAlign: 'right' }}>
          {!editing ? (
            <Button
              size="small"
              type="primary"
              onClick={() => {
                form.setFieldsValue(initialValues)
                setEditing(true)
              }}
            >
              {t('2-common.bian-ji')}
            </Button>
          ) : (
            <Space>
              <Button
                size="small"
                onClick={() => {
                  form.setFieldsValue(initialValues)
                  setEditing(false)
                }}
              >
                {t('2-common.qu-xiao')}
              </Button>
              <Button
                size="small"
                type="primary"
                disabled={freightLoading || !!freightError}
                onClick={async () => {
                  let values = await form.validateFields()
                  values = form.getFieldsValue(true)
                  try {
                    setState({ loading: t('Order.zheng-zai-bao-cun') })
                    await apiAddOrEditLogistics(
                      detail.sysOrderId,
                      values.channelCode!,
                      values.estimatedFreight!,
                      values.registrationFreight!,
                    )
                    setState(prev => {
                      const nextDetail = produce(prev.detail!, draft => {
                        Object.assign(draft, values)
                      })
                      return { detail: nextDetail }
                    })
                    setEditing(false)
                  } finally {
                    setState({ loading: false })
                  }
                }}
              >
                {t('2-common.bao-cun')}
              </Button>
            </Space>
          )}
        </div>
      )}
    </div>
  )
})
