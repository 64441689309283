import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Popover, Space, Tag, Tooltip } from 'antd'
import Icon from '@ant-design/icons'

import { PlatFormShow } from '@/pages/Biz/components/PlatFormShow'
import type { IOrder } from '../../interface'
import BlacklistIcon from '@/assets/icons/blacklist.svg?react'
import Note1Icon from '@/assets/icons/note1.svg?react'
import Note2Icon from '@/assets/icons/note2.svg?react'
import styles from './styles.module.less'

/** 订单标签 */
export const OrderTags = ({
  data: { platForm, buyerNote, sellerNote, splitFlag },
  style,
  className,
}: {
  data: Pick<IOrder, 'platForm' | 'buyerNote' | 'sellerNote' | 'splitFlag'>
  style?: CSSProperties
  className?: string
}) => {
  const { t } = useTranslation()
  const riskLevel = null // TODO: 待开发
  const blacklistCause = null // TODO: 待开发

  return (
    <Space style={style} className={classNames(styles.box, className)} wrap size={3}>
      {riskLevel && (
        <Popover
          mouseLeaveDelay={0}
          content={
            <Space size={4}>
              <PlatFormShow platForm={platForm} />
              <span>{t('Order.biao-ji-risklevel-feng-xian-ding-dan', { riskLevel })}</span>
            </Space>
          }
        >
          <Tag className={styles.tag} color="red">
            {riskLevel}
          </Tag>
        </Popover>
      )}

      {blacklistCause && (
        <Popover
          content={
            <div style={{ maxWidth: 360, maxHeight: 200, overflow: 'auto' }}>
              <b>{'黑名单'}</b>: {blacklistCause}
            </div>
          }
        >
          <Tag className={styles.tag} icon={<Icon component={BlacklistIcon} />} />
        </Popover>
      )}

      <Popover
        content={
          buyerNote && (
            <div style={{ maxWidth: 360, maxHeight: 200, overflow: 'auto' }}>
              <b>{t('Order.mai-jia-bei-zhu')}: </b>
              {buyerNote}
            </div>
          )
        }
      >
        <Tag
          className={styles.tag}
          color="orange"
          style={{
            opacity: buyerNote ? undefined : 0.6,
            cursor: buyerNote ? undefined : 'not-allowed',
            filter: buyerNote ? undefined : 'grayscale(1)',
          }}
          icon={<Icon component={Note1Icon} />}
        />
      </Popover>

      <Popover
        content={
          sellerNote && (
            <div style={{ maxWidth: 360, maxHeight: 200, overflow: 'auto' }}>
              <b>{t('Order.mai4-jia-bei-zhu')}: </b>
              {sellerNote}
            </div>
          )
        }
      >
        <Tag
          className={styles.tag}
          color="orange"
          style={{
            opacity: sellerNote ? undefined : 0.6,
            cursor: sellerNote ? undefined : 'not-allowed',
            filter: sellerNote ? undefined : 'grayscale(1)',
          }}
          icon={<Icon component={Note2Icon} />}
        />
      </Popover>

      {!!splitFlag && (
        <Tooltip mouseLeaveDelay={0} title={t('Order.chai-fen-ding-dan')}>
          <Tag className={styles.tag} color="green">
            {t('Order.chai')}
          </Tag>
        </Tooltip>
      )}
    </Space>
  )
}
