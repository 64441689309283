import { memo, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { Descriptions, Space, Spin, Tabs } from 'antd'

import { Loading, SPIN_DELAY } from '@/components/Loading'
import { toThousands } from '@/utils/utils'
import { CAction } from './CAction'
import { CAddress } from './CAddress'
import { CLocalProduct } from './CLocalProduct'
import { CLogistics } from './CLogistics'
import { CheckStatusShow } from './components/CheckStatusShow'
import { CProduct } from './CProduct'
import { CSellerNote } from './CSellerNote'
import { Ctx, CtxProvider } from './Ctx'
import { IOrderDetailProps } from './interface'
import { addressValidate } from './utils'

export const Detail = memo(function Detail({ footer }: { footer?: ReactNode }) {
  const { t } = useTranslation()
  const loading = useContextSelector(Ctx, v => v.loading)
  const detail = useContextSelector(Ctx, v => v.detail)
  const editable = useContextSelector(Ctx, v => v.editable)
  const defaultActiveTab = useContextSelector(Ctx, v => v.defaultActiveTab)

  const [activeKey, setActiveKey] = useState<string>(defaultActiveTab)

  if (!detail) return <Loading />

  return (
    <Spin spinning={!!loading} tip={typeof loading === 'string' ? loading : undefined} delay={SPIN_DELAY}>
      <Descriptions
        size="small"
        bordered
        column={3}
        items={[
          {
            label: t('Order.mai-jia-xing-ming'),
            span: 1,
            children: detail.orderAddress.name || '--',
          },
          {
            label: t('Order.mai-jia-you-xiang'),
            span: 1,
            children: detail.email || '--',
          },
          {
            label: t('Order.ding-dan-jin-e'),
            span: 1,
            children: <span style={{ whiteSpace: 'nowrap' }}>{toThousands(detail.currentTotalPrice)}</span>,
          },
          {
            label: t('Order.mai-jia-bei-zhu'),
            span: 3,
            children: detail.buyerNote || '--',
          },
          {
            label: t('Order.mai4-jia-bei-zhu'),
            span: 3,
            children: <CSellerNote />,
          },
        ]}
      />

      <Tabs
        size="small"
        style={{ marginTop: 18 }}
        tabBarStyle={{ marginBottom: 0 }}
        centered
        animated={false}
        activeKey={activeKey}
        onChange={setActiveKey}
        items={[
          {
            key: 'address',
            label: (
              <>
                {t('Order.shou-huo-di-zhi')}
                {editable && (
                  <CheckStatusShow
                    isErr={!addressValidate(detail.orderAddress)}
                    errTip={t('Order.xiao-yan-bu-tong-guo-qing-bian-ji-xiao-zheng')}
                  />
                )}
              </>
            ),
            children: (
              <div style={{ padding: 16 }}>
                <CAddress />
              </div>
            ),
          },
          {
            key: 'logistics',
            label: (
              <>
                {t('Order.cang-ku-wu-liu')}
                {editable && (
                  <CheckStatusShow
                    isErr={!detail.channelCode}
                    errTip={t('Order.xiao-yan-bu-tong-guo-qing-bian-ji-xiao-zheng')}
                  />
                )}
              </>
            ),
            children: (
              <div style={{ padding: 16 }}>
                <CLogistics />
              </div>
            ),
          },
        ]}
      />

      <div style={{ marginTop: 16 }}>
        {editable || detail.orderLocalProductList?.length ? (
          <CLocalProduct />
        ) : detail.orderProductMatchList?.length ? (
          <CProduct />
        ) : null}
      </div>

      {footer && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30 }}>
          <Space>{footer}</Space>
        </div>
      )}
    </Spin>
  )
})

/** 订单详情组件（外部使用） */
export const OrderDetail = (props: IOrderDetailProps) => {
  return (
    <CtxProvider {...props}>
      <Detail footer={<CAction />} />
    </CtxProvider>
  )
}
