import type { IOrderDetail } from '../../interface'
import type { TAddressFormValues } from './CAddress'

/** 获取收货地址校验规则 */
export const getAddressRule = (name: keyof TAddressFormValues): { required: boolean } => {
  let required = true
  if (name === 'address2' || name === 'company') required = false
  return { required }
}

/** 收货地址校验 */
export const addressValidate = (values: Omit<IOrderDetail['orderAddress'], 'sysOrderId'>): boolean => {
  const keys = [
    'name',
    'phone',
    'country',
    'countryCode',
    'province',
    'city',
    'address1',
    'address2',
    'company',
    'zip',
  ] as const

  for (const k of keys) {
    const { required } = getAddressRule(k)
    const v = values[k]
    if (required && !v) return false
  }

  return true
}
