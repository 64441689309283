import { memo } from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { Badge, Button, Space } from 'antd'

import { CusTable } from '@/components/CusTable'
import { LocalProductCell } from '@/pages/Biz/components/LocalProductCell'
import { ProductCell } from '@/pages/Biz/components/ProductCell'
import { TAB_TYPE } from '../../consts'
import type { IOrderDetail } from '../../interface'
import { CheckStatusShow } from './components/CheckStatusShow'
import { Ctx } from './Ctx'

type IRecord = NonNullable<IOrderDetail['orderProductMatchList']>[number]

/** 商品列表 */
export const CProduct = memo(function CProduct() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isEmployee = useContextSelector(Ctx, v => v.isEmployee)
  const orderNumber = useContextSelector(Ctx, v => v.orderNumber)
  const list = useContextSelector(Ctx, v => v.detail!.orderProductMatchList) || []
  const $businessStatuss = useContextSelector(Ctx, v => v.detail!.$businessStatuss)

  return (
    <CusTable<IRecord>
      rowKey="skuId"
      columns={[
        {
          title: t('Order.ding-dan-hao'),
          render: () => orderNumber,
        },
        {
          title: t('Order.shang-pin-xin-xi'),
          render: (_v, { imgUrl, sku, skuId, currentQuantity, price, title }) => (
            <ProductCell img={imgUrl} sku={sku} skuId={skuId} count={currentQuantity} price={price} title={title} />
          ),
        },
        {
          title: (
            <>
              {t('Order.chan-pin-pei-dui')}
              {!isEmployee && $businessStatuss === TAB_TYPE.待匹配 && list.some(d => !d.localSkuId) && (
                <CheckStatusShow isErr errTip={t('Order.you-shang-pin-wei-jin-hang-chan-pin-pei-dui')} />
              )}
            </>
          ),
          render: (_v, { localSkuId, localImgUrl, localSku, localProductEnTitle }) =>
            !localSkuId ? (
              <Space size={0}>
                <Badge color="red" text={t('Order.wei-pei-dui')} />
                <Button type="link" onClick={() => navigate('/biz/shop/productMatch?matchStatus=0')}>
                  {t('Order.qu-pi-pei')}
                </Button>
              </Space>
            ) : (
              <LocalProductCell img={localImgUrl} sku={localSku} skuId={localSkuId} title={localProductEnTitle} />
            ),
        },
      ]}
      dataSource={list}
    />
  )
})
