/** 商家侧业务状态 */
export enum TAB_TYPE {
  待匹配 = 1,
  待付款 = 2,
  待审核 = 3,
  待处理 = 4,
  已搁置 = 45,
  待发货 = 35,
  已发货或订单追踪 = 40,
  已取消 = 5,
  非ShipO = 6,

  异常订单 = 30,

  运单_无货 = 22,
  运单_有货 = 21,
}

/** 员工侧业务状态 */
export enum TAB_TYPE2 {
  待审核 = 50,
  待预报 = 55,
  待打印 = 60,
  待推送 = 65,
  待打包 = 70,
  已发货或运单追踪 = 80,
  已取消 = 85,

  异常履约单 = 90,
}

/** 商家侧订单状态 */
export enum STATUS {
  未付款 = 1,
  已付款 = 2,
  部分退款 = 3,
  已退款 = 4,
  已取消 = 5,
  ShipO审核中 = 20,
  已驳回 = 30,
  待预报 = 12,
  预报失败 = 13,
  有货 = 10,
  无货 = 11,
  待打包 = 21,
  已出库 = 22,
  已发货 = 40,
  已签收 = 41,
}

/** 员工侧订单状态 */
export enum STATUS2 {
  有货 = 50,
  无货 = 55,
  待预报 = 60,
  预报失败 = 65,
  待打印 = 70,
  打印失败 = 71,
  待推送 = 75,
  推送失败 = 76,
  待打包 = 80,
  已发货 = 90,
  已签收 = 95,
}
