import { FunctionComponent, ReactElement, ReactNode } from 'react'
import { Fragment } from 'react/jsx-runtime'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { Button, Dropdown, message, Modal, Space, Tooltip } from 'antd'
import Icon from '@ant-design/icons'

import { apiLogisticsIntercept, apiLogisticsTracking } from '../apis'
import { addressValidate, OrderDetailDrawer } from '../components/OrderDetail'
import { STATUS, STATUS2, TAB_TYPE, TAB_TYPE2 } from '../consts'
import { IOrder } from '../interface'
import {
  ChangeBizStatus,
  EmployeeApprovals,
  EmployeeForecast,
  EmployeePrint,
  EmployeePushToWms,
  restoreOrder,
  ReviveProcess,
  SubmitFulfillment,
} from '../operations'
import { Ctx } from './Ctx'
import DetailsIcon from '@/assets/icons/details.svg?react'
import ForecastIcon from '@/assets/icons/forecast.svg?react'
import InterceptIcon from '@/assets/icons/intercept.svg?react'
import MatchIcon from '@/assets/icons/match.svg?react'
import MoreIcon from '@/assets/icons/more.svg?react'
import MoveToPendingProcessingIcon from '@/assets/icons/moveToPendingProcessing.svg?react'
import MoveToPendingReviewIcon from '@/assets/icons/moveToPendingReview.svg?react'
import PrintIcon from '@/assets/icons/print.svg?react'
import PushIcon from '@/assets/icons/push.svg?react'
import RestoreIcon from '@/assets/icons/restore.svg?react'
import ReviewApprovedIcon from '@/assets/icons/reviewApproved.svg?react'
import SubmitForFulfillmentIcon from '@/assets/icons/submitForFulfillment.svg?react'
import SuspendIcon from '@/assets/icons/suspend.svg?react'
import ZhuizongIcon from '@/assets/icons/zhuizong.svg?react'

const useAction = (sysOrderId: string) => {
  const getList = useContextSelector(Ctx, v => v.getList)
  const deleteRecord = useContextSelector(Ctx, v => v.deleteRecord)
  const refreshTabShowCounts = useContextSelector(Ctx, v => v.refreshTabShowCounts)
  const deleteItem = () => {
    deleteRecord(sysOrderId)
    refreshTabShowCounts()
  }
  const refreshList = () => {
    getList()
    refreshTabShowCounts()
  }
  return { deleteItem, refreshList }
}

const addTooltip = (btn: ReactElement, tip: ReactNode) => (
  <Tooltip mouseEnterDelay={0} mouseLeaveDelay={0} title={tip}>
    {btn}
  </Tooltip>
)

/** 操作单元格 */
export const CellAction = ({ record }: { record: IOrder }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const tabType = useContextSelector(Ctx, v => v.tabType)
  const isEmployee = useContextSelector(Ctx, v => v.isEmployee)

  const { sysShopId, sysOrderId, orderStatus, subOrders, $parentKey, splitFlag, $unRestorable } = record
  const { orderAddress, channelCode } = record
  const btnProps = { size: 'small', type: 'link' } as const

  const { deleteItem, refreshList } = useAction(sysOrderId)

  /** 商家侧变更业务状态 */
  const getChangeBizStatusBtn = <T extends FunctionComponent | null>(
    btnText: string,
    confirmTitle: string,
    toStatus: TAB_TYPE | null,
    SvgIcon: T,
    danger?: boolean | null,
  ): T extends FunctionComponent ? ReactElement : { label: ReactElement } => {
    const node = (
      <ChangeBizStatus
        shopOrders={{ sysShopId, sysOrderId }}
        confirmTitle={confirmTitle}
        curBizStatus={tabType as TAB_TYPE}
        changeBizStatus={toStatus ?? undefined}
        onSuccess={deleteItem}
      >
        {({ handleClick }) =>
          SvgIcon ? (
            addTooltip(
              <Button {...btnProps} icon={<Icon component={SvgIcon} />} danger={!!danger} onClick={handleClick} />,
              btnText,
            )
          ) : (
            <a style={{ color: danger ? 'var(--ant-color-error)' : undefined }} onClick={handleClick}>
              {btnText}
            </a>
          )
        }
      </ChangeBizStatus>
    )
    return SvgIcon ? node : ({ label: node } as any)
  }

  /** 拦截运单 */
  const getInterceptIconBtn = <T extends boolean>(
    isMenu: T,
  ): T extends true ? { label: ReactElement } : ReactElement => {
    const handleClick = () =>
      Modal.confirm({
        title: t('Order.que-ren-yao-lan-jie-yun-dan-ma'),
        content: record.orderNumber,
        onOk: async () => {
          await apiLogisticsIntercept(sysOrderId)
          message.success('操作成功')
          deleteItem()
        },
      })
    if (isMenu) {
      return {
        label: (
          <a style={{ color: 'var(--ant-color-error)' }} onClick={handleClick}>
            {t('Order.lan-jie-yun-dan')}
          </a>
        ),
      } as any
    }
    return addTooltip(
      <Button {...btnProps} icon={<Icon component={InterceptIcon} />} danger onClick={handleClick} />,
      t('Order.lan-jie-yun-dan'),
    ) as any
  }

  /** 物流追踪 */
  const getLogisticsTrackingBtn = <T extends boolean>(
    isMenu: T,
  ): T extends true ? { label: ReactElement } : ReactElement => {
    const handleClick = async () => {
      const url = await apiLogisticsTracking(sysOrderId)
      if (url) window.open(url)
      else message.info(t('Order.zan-wu-wu-liu-zhui-zong-xin-xi'))
    }
    if (isMenu) {
      return {
        label: <a onClick={handleClick}>{t('Order.wu-liu-zhui-zong')}</a>,
      } as any
    }
    return addTooltip(
      <Button {...btnProps} icon={<Icon component={ZhuizongIcon} />} onClick={handleClick} />,
      t('Order.wu-liu-zhui-zong'),
    ) as any
  }

  // ====== 父行 ======
  if (subOrders?.length) {
    if (isEmployee) return null // 员工侧父行无操作
    if (tabType !== TAB_TYPE.待处理 && tabType !== TAB_TYPE.已搁置) return null // 其它页签不渲染
    const btns: ReactElement[] = []
    if (tabType === TAB_TYPE.待处理) {
      btns.push(
        addTooltip(
          <Button
            {...btnProps}
            icon={<Icon component={RestoreIcon} />}
            disabled={$unRestorable}
            onClick={() => restoreOrder({ sysShopId, sysOrderId, onSuccess: refreshList })}
          />,
          t('Order.huan-yuan-ding-dan'),
        ),
        getChangeBizStatusBtn(
          t('Order.ge-zhi-ding-dan'),
          t('Order.que-ren-yao-ge-zhi-ding-dan-ma'),
          TAB_TYPE.已搁置,
          SuspendIcon,
        ),
      )
    }
    if (tabType === TAB_TYPE.已搁置) {
      btns.push(getChangeBizStatusBtn(t('Order.huan-yuan'), t('Order.que-ren-yao-huan-yuan-ma'), null, RestoreIcon))
    }
    return (
      <Space size={0}>
        {btns.map((btn, i) => (
          <Fragment key={i}>{btn}</Fragment>
        ))}
      </Space>
    )
  }

  // ====== 商家侧 ======
  const getCustomerBtns = () => {
    const btns: ReactElement[] = []
    const menus: { label: ReactElement; disabled?: boolean }[] = []

    switch (tabType) {
      case TAB_TYPE.待匹配:
        btns.push(
          addTooltip(
            <Button
              {...btnProps}
              icon={<Icon component={MatchIcon} />}
              onClick={() => navigate('/biz/shop/productMatch?matchStatus=0')}
            />,
            t('Order.qu-pi-pei'),
          ),
        )
        break

      case TAB_TYPE.待付款:
        btns.push(
          getChangeBizStatusBtn(
            t('Order.yi-ru-dai-shen-he'),
            t('Order.que-ren-yao-yi-ru-dai-shen-he-ma'),
            TAB_TYPE.待审核,
            MoveToPendingReviewIcon,
          ),
        )
        break

      case TAB_TYPE.待审核:
        btns.push(
          getChangeBizStatusBtn(
            t('Order.shen-he-tong-guo'),
            t('Order.que-ren-yao-shen-he-tong-guo-ma'),
            TAB_TYPE.待处理,
            ReviewApprovedIcon,
          ),
        )
        menus.push(
          getChangeBizStatusBtn(
            t('Order.ge-zhi-ding-dan'),
            t('Order.que-ren-yao-ge-zhi-ding-dan-ma'),
            TAB_TYPE.已搁置,
            null,
          ),
        )
        menus.push(
          getChangeBizStatusBtn(
            t('Order.fei-chu-ding-dan'),
            t('Order.que-ren-yao-fei-chu-ding-dan-ma'),
            TAB_TYPE.已取消,
            null,
            true,
          ),
        )
        break

      case TAB_TYPE.待处理:
        btns.push(
          <SubmitFulfillment shopOrders={{ sysShopId, sysOrderId }} onSuccess={deleteItem}>
            {({ handleClick }) =>
              addTooltip(
                <Button
                  {...btnProps}
                  icon={<Icon component={SubmitForFulfillmentIcon} />}
                  disabled={!addressValidate(orderAddress) || !channelCode}
                  onClick={handleClick}
                />,
                t('Order.ti-jiao-lv-yue'),
              )
            }
          </SubmitFulfillment>,
        )
        if (!$parentKey) {
          menus.push(
            getChangeBizStatusBtn(
              t('Order.ge-zhi-ding-dan'),
              t('Order.que-ren-yao-ge-zhi-ding-dan-ma'),
              TAB_TYPE.已搁置,
              null,
            ),
          )
        }
        if (!splitFlag) {
          menus.push(
            getChangeBizStatusBtn(
              t('Order.fei-chu-ding-dan'),
              t('Order.que-ren-yao-fei-chu-ding-dan-ma'),
              TAB_TYPE.已取消,
              null,
              true,
            ),
          )
        }
        break

      case TAB_TYPE.已搁置:
        if (!$parentKey) {
          btns.push(getChangeBizStatusBtn(t('Order.huan-yuan'), t('Order.que-ren-yao-huan-yuan-ma'), null, RestoreIcon))
        }
        break

      case TAB_TYPE.异常订单:
        btns.push(
          <ReviveProcess sysShopId={sysShopId} sysOrderId={sysOrderId} onSuccess={deleteItem}>
            {({ handleClick }) =>
              addTooltip(
                <Button {...btnProps} icon={<Icon component={MoveToPendingProcessingIcon} />} onClick={handleClick} />,
                t('Order.yi-ru-dai-chu-li'),
              )
            }
          </ReviveProcess>,
        )
        break

      case TAB_TYPE.已发货或订单追踪:
        if (orderStatus === STATUS.已发货) {
          btns.push(getLogisticsTrackingBtn(false))
          menus.push(getInterceptIconBtn(true))
        } else {
          btns.push(getLogisticsTrackingBtn(false))
        }
        break
    }

    return { btns, menus }
  }

  // ====== 员工侧 ======
  const getEmployeeBtns = () => {
    const btns: ReactElement[] = []
    const menus: { label: ReactElement; disabled?: boolean }[] = []

    switch (tabType) {
      case TAB_TYPE2.待审核:
        btns.push(
          <EmployeeApprovals sysOrderIds={sysOrderId} auditType={1} onSuccess={deleteItem}>
            {({ handleClick }) =>
              addTooltip(
                <Button {...btnProps} icon={<Icon component={ReviewApprovedIcon} />} onClick={handleClick} />,
                t('Order.shen-he-tong-guo'),
              )
            }
          </EmployeeApprovals>,
        )
        menus.push({
          label: (
            <EmployeeApprovals sysOrderIds={sysOrderId} auditType={0} onSuccess={deleteItem}>
              {({ handleClick }) => (
                <a style={{ color: 'var(--ant-color-error)' }} onClick={handleClick}>
                  {t('Order.bo-hui')}
                </a>
              )}
            </EmployeeApprovals>
          ),
        })
        break

      case TAB_TYPE2.待预报:
        btns.push(
          <EmployeeForecast
            shopOrders={{ sysShopId, sysOrderId }}
            onSuccess={hasFail => (hasFail ? refreshList() : deleteItem())}
          >
            {({ handleClick }) =>
              addTooltip(
                <Button {...btnProps} icon={<Icon component={ForecastIcon} />} onClick={handleClick} />,
                t('Order.yu-bao'),
              )
            }
          </EmployeeForecast>,
        )
        menus.push({
          label: (
            <EmployeeApprovals sysOrderIds={sysOrderId} auditType={0} onSuccess={deleteItem}>
              {({ handleClick }) => (
                <a style={{ color: 'var(--ant-color-error)' }} onClick={handleClick}>
                  {t('Order.bo-hui')}
                </a>
              )}
            </EmployeeApprovals>
          ),
        })
        break

      case TAB_TYPE2.待打印:
        btns.push(
          <EmployeePrint
            shopOrders={{ sysShopId, sysOrderId }}
            onSuccess={hasFail => (hasFail ? refreshList() : deleteItem())}
          >
            {({ handleClick }) =>
              addTooltip(
                <Button {...btnProps} icon={<Icon component={PrintIcon} />} onClick={handleClick} />,
                t('Order.da-yin'),
              )
            }
          </EmployeePrint>,
        )
        break

      case TAB_TYPE2.待推送:
        btns.push(
          <EmployeePushToWms
            shopOrders={{ sysShopId, sysOrderId }}
            onSuccess={hasFail => (hasFail ? refreshList() : deleteItem())}
          >
            {({ handleClick }) =>
              addTooltip(
                <Button {...btnProps} icon={<Icon component={PushIcon} />} onClick={handleClick} />,
                t('Order.tui-song'),
              )
            }
          </EmployeePushToWms>,
        )
        break

      case TAB_TYPE2.已发货或运单追踪:
        if (orderStatus === STATUS2.已发货) {
          btns.push(getLogisticsTrackingBtn(false))
          menus.push(getInterceptIconBtn(true))
        } else {
          btns.push(getLogisticsTrackingBtn(false))
        }
        break
    }

    return { btns, menus }
  }

  const { btns, menus } = !isEmployee ? getCustomerBtns() : getEmployeeBtns()

  return (
    <Space size={0}>
      {btns.map((btn, i) => (
        <Fragment key={i}>{btn}</Fragment>
      ))}
      {addTooltip(
        <Button
          {...btnProps}
          icon={<Icon component={DetailsIcon} />}
          onClick={() =>
            OrderDetailDrawer.open({
              ...record,
              onOperationSuccess: shouldCall => {
                if (shouldCall === 'deleteItem') deleteItem()
                else if (shouldCall === 'refreshList') refreshList()
              },
            })
          }
        />,
        t('2-common.xiang-qing'),
      )}
      {menus.length > 0 && (
        <Dropdown menu={{ items: menus.map((menu, i) => ({ key: i, ...menu })) }}>
          <Button {...btnProps} icon={<Icon component={MoreIcon} />} />
        </Dropdown>
      )}
    </Space>
  )
}
