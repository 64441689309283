import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { message, Modal } from 'antd'

import { apiChangeOrderState } from '../apis'
import { TAB_TYPE } from '../consts'

type TShopOrder = { sysShopId: string; sysOrderId: string }

/** 变更业务状态操作（移入待审核、审核通过、作废订单、搁置订单、还原搁置订单-不需要传changeBizStatus） */
export const ChangeBizStatus = (props: {
  /** 传入数组则视为批量，提示语有些区别 */
  shopOrders: TShopOrder | TShopOrder[]
  /** 二次确认的标题 */
  confirmTitle: string
  /** 当前业务状态 */
  curBizStatus: TAB_TYPE
  /** 变更业务状态 */
  changeBizStatus?: TAB_TYPE
  /** 操作成功回调 */
  onSuccess?: () => void
  /** 按钮节点 */
  children: (handle: {
    /** 触发操作 */
    handleClick: () => void
  }) => ReactNode
}) => {
  const { t } = useTranslation()
  const { shopOrders, confirmTitle, curBizStatus, changeBizStatus, onSuccess, children } = props
  const orders = [shopOrders].flat(1)
  const isBatch = Array.isArray(shopOrders)

  const handleClick = () => {
    if (isBatch && !orders.length) {
      return void message.info(t('Order.qing-gou-xuan-xu-yao-pi-liang-cao-zuo-de-ding-dan'))
    }

    Modal.confirm({
      title: confirmTitle,
      content: isBatch ? t('Order.gong-xuan-zhong-len-tiao-shu-ju', { len: orders.length }) : undefined,
      onOk: async () => {
        await apiChangeOrderState({
          curBizStatus,
          changeBizStatus,
          shopOrders: orders,
        })
        message.success(t('2-common.cao-zuo-cheng-gong'))
        onSuccess?.()
      },
    })
  }

  return <>{children({ handleClick })}</>
}
