import { memo } from 'react'
import _ from 'lodash'

import { OrderPageLayout } from '../components/OrderPageLayout'
import { TAB_TYPE } from '../consts'
import { CtxProvider, Filter } from '../Ctx'
import { OrderTable } from './OrderTable'

export const OrderTrack = memo(function OrderTrack() {
  return (
    <CtxProvider tabType={TAB_TYPE.已发货或订单追踪} activeTab="" setActiveTab={_.noop}>
      <OrderPageLayout headerFilter={<Filter />} table={<OrderTable />} />
    </CtxProvider>
  )
})
