import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { Button, Space } from 'antd'

import { TAB_TYPE, TAB_TYPE2 } from '../../consts'
import {
  ChangeBizStatus,
  EmployeeApprovals,
  EmployeeForecast,
  EmployeePrint,
  EmployeePushToWms,
  ReviveProcess,
  SubmitFulfillment,
} from '../../operations'
import { CSplitModal } from './CSplitModal'
import { Ctx } from './Ctx'
import { addressValidate } from './utils'

/** 操作栏 */
export const CAction = memo(function CAction() {
  const { t } = useTranslation()
  const isEmployee = useContextSelector(Ctx, v => v.isEmployee)
  const sysShopId = useContextSelector(Ctx, v => v.sysShopId)
  const sysOrderId = useContextSelector(Ctx, v => v.sysOrderId)
  const loading = useContextSelector(Ctx, v => v.loading)
  const $businessStatus = useContextSelector(Ctx, v => v.detail!.$businessStatuss)
  const splitFlag = useContextSelector(Ctx, v => v.detail!.splitFlag) as 0 | 1
  const fulfillmentBtnDisabeld = useContextSelector(Ctx, v => {
    return (
      !addressValidate(v.detail!.orderAddress) || // 地址校验不通过
      !v.detail!.channelCode || // 无物流渠道
      !v.detail!.$localProductList.length // 无产品
    )
  })
  const separable = useContextSelector(Ctx, v => {
    const { splitFlag, $localProductList } = v.detail!
    if (splitFlag === 0 /* 未拆分 */) {
      return $localProductList.some(d => d.currentQuantity > 1) // 有其中一个大于1才可拆分
    }
    return false
  })

  const onOperationSuccess = useContextSelector(Ctx, v => v.onOperationSuccess)
  const deleteItem = () => onOperationSuccess('deleteItem')
  const refreshList = () => onOperationSuccess('refreshList')
  const btnProps = { type: 'primary', disabled: !!loading } as const

  // ====== 商家侧 ======
  const getCustomerBtns = () => {
    const getChangeBizStatusBtn = (
      btnText: string,
      confirmTitle: string,
      toStatus: TAB_TYPE | null,
      danger?: boolean,
    ) => (
      <ChangeBizStatus
        shopOrders={{ sysShopId, sysOrderId }}
        confirmTitle={confirmTitle}
        curBizStatus={$businessStatus as TAB_TYPE}
        changeBizStatus={toStatus ?? undefined}
        onSuccess={deleteItem}
      >
        {({ handleClick }) => (
          <Button {...btnProps} danger={danger} onClick={handleClick}>
            {btnText}
          </Button>
        )}
      </ChangeBizStatus>
    )

    return (
      <>
        {$businessStatus === TAB_TYPE.待付款 &&
          getChangeBizStatusBtn(t('Order.yi-ru-dai-shen-he'), t('Order.que-ren-yao-yi-ru-dai-shen-he-ma'), 3)}

        {$businessStatus === TAB_TYPE.待审核 && (
          <>
            {getChangeBizStatusBtn(t('Order.shen-he-tong-guo'), t('Order.que-ren-yao-shen-he-tong-guo-ma'), 4)}
            {!splitFlag &&
              getChangeBizStatusBtn(
                t('Order.ge-zhi-ding-dan'),
                t('Order.que-ren-yao-ge-zhi-ding-dan-ma'),
                TAB_TYPE.已搁置,
              )}
            {getChangeBizStatusBtn(t('Order.fei-chu-ding-dan'), t('Order.que-ren-yao-fei-chu-ding-dan-ma'), 5, true)}
          </>
        )}

        {$businessStatus === TAB_TYPE.待处理 && (
          <>
            <SubmitFulfillment shopOrders={{ sysShopId, sysOrderId }} onSuccess={deleteItem}>
              {({ handleClick }) => (
                <Button {...btnProps} disabled={btnProps.disabled || fulfillmentBtnDisabeld} onClick={handleClick}>
                  {t('Order.ti-jiao-lv-yue')}
                </Button>
              )}
            </SubmitFulfillment>
            <Button {...btnProps} disabled={btnProps.disabled || !separable} onClick={CSplitModal.open}>
              {t('Order.chai-fen-ding-dan')}
            </Button>
            {!splitFlag &&
              getChangeBizStatusBtn(
                t('Order.ge-zhi-ding-dan'),
                t('Order.que-ren-yao-ge-zhi-ding-dan-ma'),
                TAB_TYPE.已搁置,
              )}
            {!splitFlag &&
              getChangeBizStatusBtn(t('Order.fei-chu-ding-dan'), t('Order.que-ren-yao-fei-chu-ding-dan-ma'), 5, true)}
          </>
        )}

        {$businessStatus === TAB_TYPE.已搁置 &&
          !splitFlag &&
          getChangeBizStatusBtn(t('Order.huan-yuan'), t('Order.que-ren-yao-huan-yuan-ma'), null)}

        {$businessStatus === TAB_TYPE.异常订单 && (
          <ReviveProcess sysShopId={sysShopId} sysOrderId={sysOrderId} onSuccess={deleteItem}>
            {({ handleClick }) => (
              <Button {...btnProps} onClick={handleClick}>
                {t('Order.yi-ru-dai-chu-li')}
              </Button>
            )}
          </ReviveProcess>
        )}
      </>
    )
  }

  // ====== 员工侧 ======
  const getEmployeeBtns = () => {
    const getRejectBtn = () => (
      <EmployeeApprovals sysOrderIds={sysOrderId} auditType={0} onSuccess={deleteItem}>
        {({ handleClick }) => (
          <Button {...btnProps} onClick={handleClick}>
            {t('Order.bo-hui')}
          </Button>
        )}
      </EmployeeApprovals>
    )

    return (
      <>
        {$businessStatus === TAB_TYPE2.待审核 && (
          <>
            <EmployeeApprovals sysOrderIds={sysOrderId} auditType={1} onSuccess={deleteItem}>
              {({ handleClick }) => (
                <Button {...btnProps} onClick={handleClick}>
                  {t('Order.shen-he-tong-guo')}
                </Button>
              )}
            </EmployeeApprovals>
            {getRejectBtn()}
          </>
        )}

        {$businessStatus === TAB_TYPE2.待预报 && (
          <>
            <EmployeeForecast
              shopOrders={{ sysShopId, sysOrderId }}
              onSuccess={hasFail => (hasFail ? refreshList() : deleteItem())}
            >
              {({ handleClick }) => (
                <Button {...btnProps} onClick={handleClick}>
                  {t('Order.yu-bao')}
                </Button>
              )}
            </EmployeeForecast>
            {getRejectBtn()}
          </>
        )}

        {$businessStatus === TAB_TYPE2.待打印 && (
          <>
            <EmployeePrint
              shopOrders={{ sysShopId, sysOrderId }}
              onSuccess={hasFail => (hasFail ? refreshList() : deleteItem())}
            >
              {({ handleClick }) => (
                <Button {...btnProps} onClick={handleClick}>
                  {t('Order.da-yin')}
                </Button>
              )}
            </EmployeePrint>
          </>
        )}

        {$businessStatus === TAB_TYPE2.待推送 && (
          <>
            <EmployeePushToWms
              shopOrders={{ sysShopId, sysOrderId }}
              onSuccess={hasFail => (hasFail ? refreshList() : deleteItem())}
            >
              {({ handleClick }) => (
                <Button {...btnProps} onClick={handleClick}>
                  {t('Order.tui-song')}
                </Button>
              )}
            </EmployeePushToWms>
          </>
        )}
      </>
    )
  }

  return (
    <Space style={{ marginLeft: 16 }}>
      {!isEmployee && getCustomerBtns()}
      {isEmployee && getEmployeeBtns()}
    </Space>
  )
})
