import { ReactNode, Reducer, useLayoutEffect, useMemo, useReducer, useRef } from 'react'
import { useMemoizedFn } from 'ahooks'
import _ from 'lodash'

import { useGlobalState } from '@/globalStore/globalStore'
import { apiGetOrderDetail } from '../../../apis'
import { TAB_TYPE } from '../../../consts'
import { emitter } from '../../../utils'
import type { IOrderDetailProps } from '../interface'
import { createInitCtxState, Ctx, CTX_STATE_KEYS, initCtxVal, popupSlot } from './Ctx'
import { ICtxState, ICtxVal } from './interface'

const reducer: Reducer<ICtxState, Parameters<ICtxVal['setState']>[0]> = (prev, action) => {
  if (action === prev) return prev
  const nextSate = {
    ...prev,
    ..._.pick(typeof action === 'function' ? action(prev) : action, CTX_STATE_KEYS),
  }
  const prevDetail = prev.detail
  let nextDetail = nextSate.detail

  if (prevDetail && nextDetail) {
    // 数据联动
    const clearChannel = () => {
      nextSate.detail = {
        ...nextDetail!,
        channelCode: null,
        channelEName: null,
        estimatedFreight: null,
        registrationFreight: null,
      }
      nextDetail = nextSate.detail
    }
    if (
      !_.isEqual(prevDetail.orderAddress.countryCode, nextDetail.orderAddress.countryCode) ||
      !_.isEqual(prevDetail.$localProductList, nextDetail.$localProductList)
    ) {
      clearChannel()
    }

    // 更新主列表项的地址信息
    if (!_.isEqual(prevDetail.orderAddress, nextDetail.orderAddress)) {
      const d = nextDetail
      Promise.resolve().then(() => {
        emitter.emit('changeOrderRecord', {
          sysOrderId: d.sysOrderId,
          changedValues: {
            orderAddress: d.orderAddress,
          },
        })
      })
    }

    // 更新主列表项的物流信息
    if (
      prevDetail.channelCode !== nextDetail.channelCode ||
      prevDetail.estimatedFreight !== nextDetail.estimatedFreight ||
      prevDetail.registrationFreight !== nextDetail.registrationFreight
    ) {
      const d = nextDetail
      Promise.resolve().then(() => {
        emitter.emit('changeOrderRecord', {
          sysOrderId: d.sysOrderId,
          changedValues: {
            channelCode: d.channelCode,
            channelEName: d.channelEName,
            estimatedFreight: d.estimatedFreight,
            registrationFreight: d.registrationFreight,
          },
        })
      })
    }
  }

  return nextSate
}

export const CtxProvider = (props: IOrderDetailProps & { children: ReactNode }) => {
  const roleCodeMap = useGlobalState(v => v.userInfo!.roleCodeMap)
  const isEmployee = !!roleCodeMap['101']
  const { platForm, sysShopId, shopName, sysOrderId, orderNumber, defaultActiveTab, onOperationSuccess, children } =
    props
  const [state, setState] = useReducer(reducer, null, (): ICtxState => {
    return createInitCtxState()
  })

  let editable = false
  if (!isEmployee && state.detail) {
    const { $businessStatuss } = state.detail
    if ($businessStatuss === TAB_TYPE.待审核 || $businessStatuss === TAB_TYPE.待处理) {
      editable = true
    }
  }

  const handleOperationSuccess = useMemoizedFn<NonNullable<ICtxVal['onOperationSuccess']>>((...args) =>
    onOperationSuccess?.(...args),
  )

  const ctxValRef = useRef<ICtxVal>(initCtxVal)
  const ctxVal = useMemo<ICtxVal>(() => {
    return (ctxValRef.current = {
      ...state,
      isEmployee,
      platForm,
      sysShopId,
      shopName,
      sysOrderId,
      orderNumber,
      editable,
      defaultActiveTab: defaultActiveTab || initCtxVal.defaultActiveTab,
      onOperationSuccess: handleOperationSuccess,
      setState,
      ctxValRef,
    })
  }, [
    defaultActiveTab,
    editable,
    handleOperationSuccess,
    isEmployee,
    orderNumber,
    platForm,
    shopName,
    state,
    sysOrderId,
    sysShopId,
  ])

  useLayoutEffect(() => {
    apiGetOrderDetail(isEmployee, { sysShopId, sysOrderId })
      .then(res => {
        setState({ detail: res })
      })
      .finally(() => {
        setState({ loading: false })
      })
  }, [isEmployee, sysOrderId, sysShopId])

  if (import.meta.env.DEV) {
    const win = window as typeof window & Record<string, unknown>
    win.__OrderDetail_Ctx = ctxVal
  }

  return (
    <Ctx.Provider value={ctxVal}>
      {children}
      <popupSlot.Slot />
    </Ctx.Provider>
  )
}
