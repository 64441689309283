import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, message, Modal } from 'antd'

import { apiApprovals } from '../../apis'
import { popupSlot } from '../../Ctx'

const updatePopup = popupSlot.insert(null)

export const RejectModal = Object.assign(
  ({
    sysOrderIds,
    onSuccess,
    destroy,
  }: {
    sysOrderIds: string | string[]
    onSuccess?: () => void
    destroy: () => void
  }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)
    const ids = [sysOrderIds].flat(1)
    const isBatch = Array.isArray(sysOrderIds)
    const [content, setContent] = useState('')
    const [loading, setLoading] = useState(false)

    return (
      <Modal
        title={
          isBatch
            ? t('Order.pi-liang-bo-hui-yuan-yin', {
                len: ids.length,
              })
            : t('Order.bo-hui-yuan-yin')
        }
        onOk={async () => {
          try {
            setLoading(true)
            await apiApprovals({
              auditResult: 0,
              sysOrderIds: ids,
              refusalReason: content.trim(),
            })
            message.success(t('2-common.cao-zuo-cheng-gong'))
            onSuccess?.()
            onHide()
          } catch (err) {
            setLoading(false)
            throw err
          }
        }}
        okText={t('Order.bo-hui')}
        okButtonProps={{ danger: true, loading, disabled: !content.trim() }}
        open={open}
        onCancel={onHide}
        afterOpenChange={afterOpenChange}
      >
        <Input.TextArea
          placeholder={t('2-common.qing-shu-ru')}
          rows={6}
          value={content}
          onChange={e => setContent(e.target.value)}
        />
      </Modal>
    )
  },
  {
    open: (props: { sysOrderIds: string | string[]; onSuccess?: () => void }) => {
      updatePopup(<RejectModal {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
