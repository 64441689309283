import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import _ from 'lodash'
import { Button, Space } from 'antd'

import { TAB_TYPE2 } from '../../consts'
import { Ctx } from '../../Ctx'
import { EmployeeApprovals, EmployeeForecast, EmployeePrint, EmployeePushToWms } from '../../operations'
import { getFlatOrderRecords } from '../../utils'

export const Action = memo(function Action() {
  const { t } = useTranslation()
  const tabType = useContextSelector(Ctx, v => v.tabType)
  const keys = useContextSelector(Ctx, v => v.selectedRowKeys)
  const records = useContextSelector(Ctx, v => v.data?.records) || []
  const getList = useContextSelector(Ctx, v => v.getList)
  const refreshTabShowCounts = useContextSelector(Ctx, v => v.refreshTabShowCounts)

  const onSuccess = () => {
    getList()
    refreshTabShowCounts()
  }

  return (
    <Space>
      {tabType === TAB_TYPE2.待审核 && (
        <EmployeeApprovals sysOrderIds={keys} auditType={1} onSuccess={onSuccess}>
          {({ handleClick }) => (
            <Button type="primary" onClick={handleClick}>
              {t('Order.pi-liang-tong-guo')}
            </Button>
          )}
        </EmployeeApprovals>
      )}
      {tabType === TAB_TYPE2.待预报 && (
        <EmployeeForecast
          shopOrders={getFlatOrderRecords(records, keys).map(d => _.pick(d, ['sysShopId', 'sysOrderId']))}
          onSuccess={onSuccess}
        >
          {({ handleClick }) => (
            <Button type="primary" onClick={handleClick}>
              {t('Order.pi-liang-yu-bao')}
            </Button>
          )}
        </EmployeeForecast>
      )}
      {tabType === TAB_TYPE2.待打印 && (
        <EmployeePrint
          shopOrders={getFlatOrderRecords(records, keys).map(d => _.pick(d, ['sysShopId', 'sysOrderId']))}
          onSuccess={onSuccess}
        >
          {({ handleClick }) => (
            <Button type="primary" onClick={handleClick}>
              {t('Order.pi-liang-da-yin')}
            </Button>
          )}
        </EmployeePrint>
      )}
      {tabType === TAB_TYPE2.待推送 && (
        <EmployeePushToWms
          shopOrders={getFlatOrderRecords(records, keys).map(d => _.pick(d, ['sysShopId', 'sysOrderId']))}
          onSuccess={onSuccess}
        >
          {({ handleClick }) => (
            <Button type="primary" onClick={handleClick}>
              {t('Order.pi-liang-tui-song')}
            </Button>
          )}
        </EmployeePushToWms>
      )}
    </Space>
  )
})
