import { CSSProperties } from 'react'
import classNames from 'classnames'

import { LoadingIcon } from '@/components/Loading'
import styles from './styles.module.less'

/** 展开与折叠功能图标 */
export const ExpandIcon = ({
  collapsed,
  loading,
  onClick,
  style,
  className,
}: {
  /** @default true */
  collapsed?: boolean
  loading?: boolean
  onClick?: () => void
  style?: CSSProperties
  className?: string
}) => {
  if (collapsed === undefined) collapsed = true

  if (loading) return <LoadingIcon />
  return (
    <button
      type="button"
      style={style}
      className={classNames(!!collapsed && styles.collapsed, styles.icon, className)}
      onClick={() => onClick?.()}
    />
  )
}
