import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { message, Modal } from 'antd'

import { SuccessAndFailResultModal } from '../../components/SuccessAndFailResultModal'
import { apiInFulfillment } from '../apis'
import { popupSlot } from '../Ctx'

type TShopOrder = { sysShopId: string; sysOrderId: string }

/** 提交履约操作 */
export const SubmitFulfillment = (props: {
  /** 传入数组则视为批量，提示语有些区别 */
  shopOrders: TShopOrder | TShopOrder[]
  /** 操作成功回调 */
  onSuccess?: () => void
  /** 按钮节点 */
  children: (handle: {
    /** 触发操作 */
    handleClick: () => void
  }) => ReactNode
}) => {
  const { t } = useTranslation()
  const { shopOrders, onSuccess, children } = props
  const orders = [shopOrders].flat(1)
  const isBatch = Array.isArray(shopOrders)

  const handleClick = () => {
    if (isBatch && !orders.length) {
      return void message.info(t('Order.qing-gou-xuan-xu-yao-pi-liang-cao-zuo-de-ding-dan'))
    }
    if (isBatch && orders.length > 20) {
      return void message.info(t('Order.zui-duo-zhi-neng-pi-liang-cao-zuo-20-tiao-ji-lu'))
    }

    Modal.confirm({
      title: isBatch ? t('Order.que-ren-yao-pi-liang-ti-jiao-lv-yue-ma') : t('Order.que-ren-yao-ti-jiao-lv-yue-ma'),
      content: isBatch ? t('Order.gong-xuan-zhong-len-tiao-shu-ju', { len: orders.length }) : undefined,
      onOk: async () => {
        const res = await apiInFulfillment({ shopOrders: orders })
        if (res.failTotal > 0) {
          const updatePopup = popupSlot.insert(null)
          updatePopup(
            <SuccessAndFailResultModal
              result={res}
              destroy={() => updatePopup(null)}
              onClose={() => {
                if (res.successTotal > 0) {
                  onSuccess?.()
                }
              }}
            />,
          )
        } else {
          message.success(t('2-common.cao-zuo-cheng-gong'))
          onSuccess?.()
        }
      },
    })
  }

  return <>{children({ handleClick })}</>
}
