import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import _ from 'lodash'
import { Button, message, Modal, Space } from 'antd'
import { SyncOutlined } from '@ant-design/icons'

import { apiSyncOrder } from '../apis'
import { addressValidate } from '../components/OrderDetail'
import { TAB_TYPE } from '../consts'
import { Ctx } from '../Ctx'
import { ChangeBizStatus, SubmitFulfillment } from '../operations'
import { getFlatOrderRecords } from '../utils'

export const Action = memo(function Action() {
  const { t } = useTranslation()
  const tabType = useContextSelector(Ctx, v => v.tabType)
  const keys = useContextSelector(Ctx, v => v.selectedRowKeys)
  const records = useContextSelector(Ctx, v => v.data?.records) || []
  const getList = useContextSelector(Ctx, v => v.getList)
  const refreshTabShowCounts = useContextSelector(Ctx, v => v.refreshTabShowCounts)

  const getChangeBizStatusBtn = (
    btnText: string,
    confirmTitle: string,
    toStatus: TAB_TYPE | null,
    disabledWhenHasSubOrder?: boolean,
  ) => (
    <ChangeBizStatus
      shopOrders={getFlatOrderRecords(records, keys).map(d => _.pick(d, ['sysShopId', 'sysOrderId']))}
      confirmTitle={confirmTitle}
      curBizStatus={tabType as TAB_TYPE}
      changeBizStatus={toStatus ?? undefined}
      onSuccess={() => {
        getList()
        refreshTabShowCounts()
      }}
    >
      {({ handleClick }) => (
        <Button
          type="primary"
          disabled={disabledWhenHasSubOrder ? getFlatOrderRecords(records, keys).some(d => d.$parentKey) : undefined}
          onClick={handleClick}
        >
          {btnText}
        </Button>
      )}
    </ChangeBizStatus>
  )

  return (
    <Space>
      {tabType === TAB_TYPE.待审核 && (
        <>
          {getChangeBizStatusBtn(
            t('Order.pi-liang-shen-he'),
            t('Order.que-ren-yao-pi-liang-shen-he-ma'),
            TAB_TYPE.待处理,
          )}
          {false &&
            getChangeBizStatusBtn(
              t('Order.pi-liang-ge-zhi'),
              t('Order.que-ren-yao-pi-liang-ge-zhi-ma'),
              TAB_TYPE.已搁置,
              true,
            )}
        </>
      )}

      {tabType === TAB_TYPE.待处理 && (
        <>
          <SubmitFulfillment
            shopOrders={getFlatOrderRecords(records, keys).map(d => _.pick(d, ['sysShopId', 'sysOrderId']))}
            onSuccess={() => {
              getList()
              refreshTabShowCounts()
            }}
          >
            {({ handleClick }) => (
              <Button
                type="primary"
                disabled={getFlatOrderRecords(records, keys).some(
                  d => !addressValidate(d.orderAddress) || !d.channelCode,
                )}
                onClick={handleClick}
              >
                {t('Order.pi-liang-ti-jiao-lv-yue')}
              </Button>
            )}
          </SubmitFulfillment>
          {false &&
            getChangeBizStatusBtn(
              t('Order.pi-liang-ge-zhi'),
              t('Order.que-ren-yao-pi-liang-ge-zhi-ma'),
              TAB_TYPE.已搁置,
              true,
            )}
        </>
      )}

      {false &&
        tabType === TAB_TYPE.已搁置 &&
        getChangeBizStatusBtn(t('Order.pi-liang-huan-yuan'), t('Order.que-ren-yao-pi-liang-huan-yuan-ma'), null, true)}

      {[TAB_TYPE.待匹配, TAB_TYPE.待付款, TAB_TYPE.待审核, TAB_TYPE.待处理].includes(tabType as TAB_TYPE) && (
        <Button
          type="primary"
          icon={<SyncOutlined />}
          onClick={() => {
            Modal.confirm({
              title: t('Order.que-ren-yao-tong-bu-ding-dan-ma'),
              onOk: async () => {
                const res = await apiSyncOrder()
                if (!res?.total) return void message.info(t('Order.mei-you-yao-geng-xin-de-shu-ju'))
                message.success(t('Order.cheng-gong-geng-xin-total-tiao-shu-ju', { total: res.total }))
                getList({ page: 1 })
                refreshTabShowCounts()
              },
            })
          }}
        >
          {t('Order.tong-bu-ding-dan')}
        </Button>
      )}
    </Space>
  )
})
