import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { useReactive } from 'ahooks'
import { Button, InputNumber, message, Modal, Popconfirm } from 'antd'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'

import { CusTable } from '@/components/CusTable'
import { LocalProductCell } from '@/pages/Biz/components/LocalProductCell'
import { apiSplitOrder } from '../../apis'
import type { IOrderDetail } from '../../interface'
import { CheckStatusShow } from './components/CheckStatusShow'
import { Ctx, popupSlot } from './Ctx'

type IRecord = NonNullable<IOrderDetail['orderLocalProductList']>[number] & { splits: number[] }

const updatePopup = popupSlot.insert(null)

export const CSplitModal = Object.assign(
  memo(function CSplit({ destroy }: { destroy: () => void }) {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)
    const sysShopId = useContextSelector(Ctx, v => v.sysShopId)
    const sysOrderId = useContextSelector(Ctx, v => v.sysOrderId)
    const $localProductList = useContextSelector(Ctx, v => v.detail!.$localProductList)
    const onOperationSuccess = useContextSelector(Ctx, v => v.onOperationSuccess)

    const [loading, setLoading] = useState(false)

    const data = useReactive(
      useMemo(() => {
        return {
          list: $localProductList.map<IRecord>(d => ({
            ...d,
            splits: [d.currentQuantity],
          })),
          get hasData() {
            return !!this.list.length
          },
          get splitTotal() {
            if (!this.hasData) return 0
            return this.list[0].splits.length
          },
          get everyOne() {
            if (!this.hasData) return true
            return this.list.every(d => d.currentQuantity === 1)
          },
          get totalValidate() {
            if (!this.hasData) return false
            return this.list.every(d => d.currentQuantity === d.splits.reduce((a, b) => a + b))
          },
          get splitValidates() {
            return Array(this.splitTotal)
              .fill(null)
              .map((_d, i) => this.list.map(d => d.splits[i]).some(d => d !== 0))
          },
        }
      }, [$localProductList]),
    )

    return (
      <Modal
        title={t('Order.chai-fen-ding-dan')}
        width={900}
        open={open}
        onCancel={onHide}
        afterOpenChange={afterOpenChange}
        okButtonProps={{
          loading,
          disabled:
            data.splitTotal < 2 || // 最少要有两个包囊
            data.everyOne ||
            !data.totalValidate ||
            !data.splitValidates.every(d => d),
        }}
        okText={t('Order.chai-fen')}
        onOk={async () => {
          try {
            setLoading(true)
            await apiSplitOrder({
              sysShopId,
              sysOrderId,
              packages: Array(data.splitTotal)
                .fill(null)
                .map((_d, i) => ({
                  packageSkuList: data.list
                    .filter(d => d.splits[i] !== 0)
                    .map(d => ({
                      localSkuId: d.skuId,
                      count: d.splits[i],
                    })),
                })),
            })
            message.success(t('2-common.cao-zuo-cheng-gong'))
            onHide()
            onOperationSuccess('refreshList')
          } catch (err) {
            setLoading(false)
            throw err
          }
        }}
      >
        {data.hasData && (
          <div style={{ marginTop: 16, textAlign: 'right' }}>
            <Button
              size="small"
              type="primary"
              ghost
              icon={<PlusOutlined />}
              disabled={data.everyOne}
              onClick={() => data.list.forEach(d => d.splits.push(0))} // data 操作
            >
              {t('Order.tian-jia-bao-guo')}
            </Button>
          </div>
        )}

        <CusTable<IRecord>
          rowKey="skuId"
          columns={[
            {
              title: t('Order.chan-pin-xin-xi'),
              key: '$localProductInfo',
              render: (_v, { imgUrl, sku, skuId, productEnTitle }) => (
                <LocalProductCell img={imgUrl} sku={sku} skuId={skuId} title={productEnTitle} />
              ),
            },
            {
              title: (
                <>
                  {t('2-common.zong-shu')}
                  {data.hasData && (
                    <CheckStatusShow isErr={!data.totalValidate} errTip={t('Order.bao-guo-zong-shu-yi-chang')} />
                  )}
                </>
              ),
              width: 80,
              render: (_v, { currentQuantity, splits }) => {
                const total = splits.reduce((a, b) => a + b)
                return (
                  <>
                    {currentQuantity} /{' '}
                    <span style={{ color: total !== currentQuantity ? 'red' : undefined }}>{total}</span>
                  </>
                )
              },
            },
            ...Array(data.splitTotal)
              .fill(null)
              .map((_v, i) => ({
                title: (
                  <>
                    {t('Order.bao-guon-n-shu-liang', { n: i + 1 })}
                    <CheckStatusShow
                      isErr={!data.splitValidates[i]}
                      errTip={t('Order.bao-guon-n-mei-you-fen-pei-chan-pin', { n: i + 1 })}
                    />
                    <Popconfirm
                      title={t('Order.que-ren-yao-shan-chu-bao-guo-n', { n: i + 1 })}
                      onConfirm={() => {
                        // data 操作
                        data.list.forEach(d => d.splits.splice(i, 1))
                        if (data.splitTotal === 1) {
                          data.list.forEach(d => {
                            d.splits = [d.currentQuantity] // 只有一个包裹时自动填满
                          })
                        }
                      }}
                    >
                      <Button
                        style={{ marginLeft: 4 }}
                        type="text"
                        disabled={data.splitTotal === 1}
                        icon={<CloseOutlined />}
                      />
                    </Popconfirm>
                  </>
                ),
                width: 130,
                render: (_v: unknown, record: IRecord) => {
                  const { currentQuantity, splits } = data.list.find(d => d.skuId === record.skuId)!
                  const val = splits[i]
                  return (
                    <InputNumber
                      style={{ width: 60 }}
                      min={0}
                      max={currentQuantity - splits.reduce((a, b) => a + b) + val}
                      precision={0}
                      disabled={data.everyOne}
                      value={val}
                      onChange={v => {
                        splits[i] = v || 0 // data 操作
                      }}
                    />
                  )
                },
              })),
          ]}
          dataSource={data.list}
        />
      </Modal>
    )
  }),
  {
    open: () => {
      updatePopup(<CSplitModal destroy={() => updatePopup(null)} />)
    },
  },
)
