import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { Button, Form, Input, Space } from 'antd'

import { apiUpdateOrderMain } from '../../apis'
import { Ctx } from './Ctx'

type TFormValues = Record<'sellerNote', string>

/** 备注信息 */
export const CSellerNote = memo(function CSellerNote() {
  const { t } = useTranslation()
  const sysShopId = useContextSelector(Ctx, v => v.sysShopId)
  const sysOrderId = useContextSelector(Ctx, v => v.sysOrderId)
  const sellerNote = useContextSelector(Ctx, v => v.detail!.sellerNote)
  const editable = useContextSelector(Ctx, v => v.editable)
  const setState = useContextSelector(Ctx, v => v.setState)

  const [editing, setEditing] = useState(false)
  const [form] = Form.useForm<TFormValues>()

  const initialValues = useMemo<TFormValues>(() => ({ sellerNote: sellerNote || '' }), [sellerNote])

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
      <div style={{ flex: '1 1' }}>
        {!editing ? (
          <>{initialValues.sellerNote || '--'}</>
        ) : (
          // 编辑模式
          <Form form={form} initialValues={initialValues}>
            <Form.Item style={{ marginBottom: 0 }} name="sellerNote">
              <Input.TextArea autoFocus rows={2} placeholder={t('2-common.qing-shu-ru')} allowClear />
            </Form.Item>
          </Form>
        )}
      </div>

      {editable && (
        <div style={{ flex: '0 0 auto' }}>
          {!editing ? (
            <Button
              size="small"
              type="primary"
              onClick={() => {
                form.setFieldsValue(initialValues)
                setEditing(true)
              }}
            >
              {t('2-common.bian-ji')}
            </Button>
          ) : (
            <Space>
              <Button
                size="small"
                onClick={() => {
                  form.setFieldsValue(initialValues)
                  setEditing(false)
                }}
              >
                {t('2-common.qu-xiao')}
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={async () => {
                  let values = await form.validateFields()
                  values = form.getFieldsValue(true)
                  values.sellerNote = values.sellerNote.trim()
                  try {
                    setState({ loading: t('Order.zheng-zai-bao-cun') })
                    await apiUpdateOrderMain({ ...values, sysShopId, sysOrderId })
                    setState(prev => ({ detail: { ...prev.detail!, ...values } }))
                    setEditing(false)
                  } finally {
                    setState({ loading: false })
                  }
                }}
              >
                {t('2-common.bao-cun')}
              </Button>
            </Space>
          )}
        </div>
      )}
    </div>
  )
})
