import { ReactNode, Reducer, useEffect, useMemo, useReducer, useRef } from 'react'
import { useMemoizedFn, useRequest } from 'ahooks'
import { produce } from 'immer'
import { pick } from 'lodash'
import _ from 'lodash'

import { CusTable, TCusTableIns } from '@/components/CusTable'
import { useGlobalState } from '@/globalStore/globalStore'
import { useControllerRef } from '@/hooks/useAbortController'
import { apiGetOrderCount, apiGetOrderList } from '../apis'
import { TAB_TYPE, TAB_TYPE2 } from '../consts'
import type { IOrderListReqData, IOrderListRes } from '../interface'
import { emitter } from '../utils'
import { createInitCtxState, Ctx, CTX_STATE_KEYS, initCtxVal, popupSlot } from './Ctx'
import { ICtxState, ICtxVal } from './interface'

const reducer: Reducer<ICtxState, Parameters<ICtxVal['setState']>[0]> = (prev, action) => {
  return action === prev
    ? prev
    : { ...prev, ...pick(typeof action === 'function' ? action(prev) : action, CTX_STATE_KEYS) }
}

export const CtxProvider = (props: {
  tabType: ICtxVal['tabType']
  activeTab: ICtxVal['activeTab']
  setActiveTab: ICtxVal['setActiveTab']
  children: ReactNode
}) => {
  const roleCodeMap = useGlobalState(v => v.userInfo!.roleCodeMap)
  const isEmployee = !!roleCodeMap['101']
  const { tabType, activeTab, setActiveTab, children } = props
  const [state, setState] = useReducer(reducer, null, (): ICtxState => {
    return createInitCtxState()
  })
  const { orderStatus, sysShopIds, orderNumber, page, size } = state

  const tableRef = useRef<TCusTableIns>(null)
  const reqData: { [P in keyof Required<IOrderListReqData>]: IOrderListReqData[P] } = useMemo(
    () => ({ tabType, orderStatus, sysShopIds, orderNumber, page, size }),
    [orderNumber, orderStatus, page, size, sysShopIds, tabType],
  )
  const controllerRef = useControllerRef()
  const {
    loading,
    data,
    params: [param],
    run,
    mutate,
  } = useRequest<IOrderListRes, [IOrderListReqData]>(
    req => {
      controllerRef.current.abort()
      controllerRef.current = new AbortController()
      return apiGetOrderList(isEmployee, req, controllerRef.current.signal)
    },
    {
      defaultParams: [reqData],
      onBefore: () => CusTable.scrollToTop(tableRef.current),
      onSuccess: ({ records }) => {
        setState({
          selectedRowKeys: [], // 重置
          expandedRowKeys: records.filter(d => d.splitFlag).map(d => d.sysOrderId), // 默认全展开
        })
      },
    },
  )

  /**
   * - 商家侧：1-订单列表;2-运单;3-订单追踪;4-异常订单;
   * - 员工侧：1-履约单审核;4-异常履约单;
   */
  let menuId = -1
  if (!isEmployee) {
    switch (tabType) {
      case TAB_TYPE.待匹配:
      case TAB_TYPE.待付款:
      case TAB_TYPE.待审核:
      case TAB_TYPE.待处理:
      case TAB_TYPE.已搁置:
      case TAB_TYPE.待发货:
      case TAB_TYPE.已发货或订单追踪:
      case TAB_TYPE.已取消:
      case TAB_TYPE.非ShipO:
        menuId = 1
        break
      case TAB_TYPE.运单_无货:
      case TAB_TYPE.运单_有货:
        menuId = 2
        break
      case TAB_TYPE.异常订单:
        menuId = 4
        break
    }
  } else {
    switch (tabType) {
      case TAB_TYPE2.待审核:
      case TAB_TYPE2.待预报:
      case TAB_TYPE2.待打印:
      case TAB_TYPE2.待推送:
      case TAB_TYPE2.待打包:
      case TAB_TYPE2.已发货或运单追踪:
      case TAB_TYPE2.已取消:
        menuId = 1
        break
      case TAB_TYPE2.异常履约单:
        menuId = 4
        break
    }
  }
  const { data: tabShowCounts = initCtxVal.tabShowCounts, run: refreshTabShowCounts } = useRequest<
    ICtxVal['tabShowCounts'],
    []
  >(
    async () => {
      if (!activeTab) return initCtxVal.tabShowCounts // 没有页签不请求
      return apiGetOrderCount(isEmployee, { menuId })
    },
    { cacheKey: `Order/Ctx/CtxProvider/tabShowCounts@${menuId}`, cacheTime: -1 },
  )

  const getList = useMemoizedFn<ICtxVal['getList']>(partialReqData => {
    partialReqData = _.pick(partialReqData, Object.keys(reqData)) // 只取有效属性
    if (!_.isEqual(partialReqData, _.pick(reqData, Object.keys(partialReqData)))) {
      setState(partialReqData)
    }
    run({ ...reqData, ...partialReqData })
  })

  const changeRecord = useMemoizedFn<ICtxVal['changeRecord']>((sysOrderId, changedValues) => {
    mutate(oldData => {
      if (!oldData) return oldData
      return produce(oldData, draft => {
        for (const item of draft.records) {
          // 第一层
          if (item.sysOrderId === sysOrderId) {
            Object.assign(item, changedValues) // 改
            return
          }
          // 第二层
          else if (item.subOrders) {
            for (const cItem of item.subOrders) {
              if (cItem.sysOrderId === sysOrderId) {
                Object.assign(cItem, changedValues) // 改
                return
              }
            }
          }
        }
      })
    })
  })

  const deleteRecord = useMemoizedFn<ICtxVal['deleteRecord']>(sysOrderId => {
    mutate(oldData => {
      if (!oldData) return oldData
      const deleteKeys: string[] = []
      const nextData = produce(oldData, draft => {
        let index = -1
        for (const item of draft.records) {
          index++
          // 第一层
          if (item.sysOrderId === sysOrderId) {
            draft.records.splice(index, 1) // 改
            deleteKeys.push(item.sysOrderId)
            return
          }
          // 第二层
          else if (item.subOrders) {
            let cIndex = -1
            for (const cItem of item.subOrders) {
              cIndex++
              if (cItem.sysOrderId === sysOrderId) {
                item.subOrders.splice(cIndex, 1) // 改
                deleteKeys.push(cItem.sysOrderId)
                if (!item.subOrders.length) {
                  draft.records.splice(index, 1) // 改，无子订单时则删除父订单
                  deleteKeys.push(item.sysOrderId)
                }
                return
              }
            }
          }
        }
      })

      // 更新相关联
      if (deleteKeys.length) {
        let { selectedRowKeys, expandedRowKeys } = state
        if (deleteKeys.some(k => selectedRowKeys.includes(k))) {
          selectedRowKeys = selectedRowKeys.filter(k => !deleteKeys.includes(k))
        }
        if (deleteKeys.some(k => expandedRowKeys.includes(k))) {
          expandedRowKeys = expandedRowKeys.filter(k => !deleteKeys.includes(k))
        }
        setState({ selectedRowKeys, expandedRowKeys })
      }

      return nextData
    })
  })

  const ctxValRef = useRef<ICtxVal>(initCtxVal)
  const ctxVal = useMemo<ICtxVal>(() => {
    return (ctxValRef.current = {
      ...state,
      isEmployee,
      tabType,
      activeTab,
      tabShowCounts,
      reqData,
      loading,
      data,
      param,
      getList,
      changeRecord,
      deleteRecord,
      setActiveTab,
      refreshTabShowCounts,
      tableRef,
      setState,
      ctxValRef,
    })
  }, [
    activeTab,
    changeRecord,
    data,
    deleteRecord,
    getList,
    isEmployee,
    loading,
    param,
    refreshTabShowCounts,
    reqData,
    setActiveTab,
    state,
    tabShowCounts,
    tabType,
  ])

  useEffect(
    () => emitter.on('changeOrderRecord', ({ sysOrderId, changedValues }) => changeRecord(sysOrderId, changedValues)),
    [changeRecord],
  )

  if (import.meta.env.DEV) {
    const win = window as typeof window & Record<string, unknown>
    win.__Order_Ctx = ctxVal
  }

  return (
    <Ctx.Provider value={ctxVal}>
      {children}
      <popupSlot.Slot />
    </Ctx.Provider>
  )
}
