import { memo } from 'react'
import { useContextSelector } from 'use-context-selector'

import { PageTabs } from '@/components/PageTabs'
import { Ctx } from '../Ctx'
import { TABS, useTAB_NAME_MAP } from './utils'

export const OrderTabs = memo(function OrderTabs() {
  const tabNameMap = useTAB_NAME_MAP()
  const activeTab = useContextSelector(Ctx, v => v.activeTab)
  const tabShowCounts = useContextSelector(Ctx, v => v.tabShowCounts)
  const setActiveTab = useContextSelector(Ctx, v => v.setActiveTab)

  return (
    <PageTabs
      activeKey={activeTab}
      onChange={setActiveTab}
      items={TABS.map(key => {
        const count = tabShowCounts.find(d => d.tabId === +key)?.count

        let showCount = ''
        if (count) {
          if (count > 99) showCount = ` (99+)`
          else showCount = ` (${count})`
        }

        return {
          key: key + '',
          label: `${tabNameMap[key]}${showCount}`,
        }
      })}
    />
  )
})
